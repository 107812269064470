import React, { useEffect, useState } from 'react'
import avtarIcon from "../Assets/Imgeas/unknown.png"
import _fetch from '../config/api'
import { api_url } from '../config/config'
import { Link, useParams } from 'react-router-dom'

const Teamtree = () => {

    const { address } = useParams()
    const [list, setlist] = useState(10)




    const getTeam = async () => {
        const formData = {
        }
        const data = await _fetch(`${api_url}report/poolTree?username=${address}`, 'get', formData, {});
        // if (data.status === 'success') {
        setlist(data?.data);
        // } else {
        //     // setregisterStatus(false);
        // }
    }

    useEffect(() => {
        getTeam()
    }, [address])


    return (
        <>
            <div className='fxied-width-container'>
                <div className='dashboard-data-container'>
                    <div className='partner-page profile_page'>
                        <div className='row m-0 '>
                            <div className='col-12 '>
                                <div className='partner-page-header'>
                                    <p> <span> Dashboard /</span> Team Tree </p>
                                    <h1 className='mb-0'>Team Tree </h1>
                                </div>
                            </div>

                            <div className='col-12'>
                                <div className="team-tree">

                                    <div className="tree__team">
                                        <div className="tf-tree mt-5 pt-5">
                                            <ul>
                                                <li>
                                                    <span className="tf-nc">
                                                        <div className="inner_main">
                                                            <Link to={'/team-tree/'+list?.userinfo?.username}><img src={avtarIcon} alt="user_logo" /></Link>
                                                            <div className="tooltip-add">
                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                    <p>Username :</p>
                                                                    <span>{list?.userinfo?.username}</span>
                                                                </div>
                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                    <p>Sponsor ID :</p>
                                                                    <span>{list?.userinfo?.sponsor_id}</span>
                                                                </div>
                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                    <p>Package :</p>
                                                                    <span>{list?.userinfo?.user_info?.current_package}</span>
                                                                </div>
                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                    <p>Team :</p>
                                                                    <span>{list?.userinfo?.directs?.active}</span>
                                                                </div>

                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                    <p> Direct Business :</p>
                                                                    <span>{list?.userinfo?.business?.direct_business}</span>
                                                                </div>
                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                    <p>Team Business :</p>
                                                                    <span>{list?.userinfo?.business?.team_business}</span>
                                                                </div>

                                                            </div>

                                                        </div>
                                                        <p>{list?.userinfo?.user_id} <hr /></p>
                                                    </span>
                                                    <ul>

                                                        {list?.data?.map((val) => {
                                                            return (
                                                                <li>



                                                                    {/* LEFT USER__ */}
                                                                    <span className="tf-nc">
                                                                        <div className="inner_main">
                                                                            <Link to={'/team-tree/'+val?.username}><img src={avtarIcon} alt="user_logo" /></Link>
                                                                            <div className="tooltip-add">
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Username :</p>
                                                                                    <span>{val?.username}</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Sponsor ID :</p>
                                                                                    <span>{val?.info?.sponsor_id}</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Package :</p>
                                                                                    <span>{val?.info?.package?.current_package}</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Team :</p>
                                                                                    <span>{val?.directs?.active}</span>
                                                                                </div>

                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p> Direct Business :</p>
                                                                                    <span>{val?.business?.direct_business}</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Team Business :</p>
                                                                                    <span>{val?.business?.team_business}</span>
                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                        <p>{val?.user_id} <hr /> </p>
                                                                    </span>
                                                                </li>
                                                            )
                                                        })}
                                                        <li className='d-none'>



                                                            {/* LEFT USER__ */}
                                                            <span className="tf-nc">
                                                                <div className="inner_main">
                                                                    <a href="#"><img src={avtarIcon} alt="user_logo" /></a>
                                                                    <div className="tooltip-add">
                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                            <p>Username :</p>
                                                                            <span>admin</span>
                                                                        </div>
                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                            <p>Sponsor ID :</p>
                                                                            <span>none</span>
                                                                        </div>
                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                            <p>Package :</p>
                                                                            <span>1000</span>
                                                                        </div>
                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                            <p>Left team :</p>
                                                                            <span>1112</span>
                                                                        </div>
                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                            <p>Right team :</p>
                                                                            <span>5</span>
                                                                        </div>
                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                            <p>5</p>

                                                                        </div>
                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                            <p> Left Green Team  :</p>
                                                                            <span>16</span>
                                                                        </div>
                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                            <p>Right Green Team :</p>
                                                                            <span>0</span>
                                                                        </div>
                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                            <p>Left Business :</p>
                                                                            <span>112019000</span>
                                                                        </div>
                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                            <p>Right Business :</p>
                                                                            <span>0</span>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <p>admin <br />Administrator </p>
                                                            </span>

                                                            {/* INNER USER___ */}
                                                            <ul>
                                                                <li>
                                                                    <span className="tf-nc">
                                                                        <div className="inner_main">
                                                                            <a href="#"><img src={avtarIcon} alt="user_logo" /></a>
                                                                            <div className="tooltip-add">
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Username :</p>
                                                                                    <span>admin</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Sponsor ID :</p>
                                                                                    <span>none</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Package :</p>
                                                                                    <span>1000</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Left team :</p>
                                                                                    <span>1112</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Right team :</p>
                                                                                    <span>5</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>5</p>

                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p> Left Green Team  :</p>
                                                                                    <span>16</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Right Green Team :</p>
                                                                                    <span>0</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Left Business :</p>
                                                                                    <span>112019000</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Right Business :</p>
                                                                                    <span>0</span>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <p>admin <br />Administrator</p>
                                                                    </span>
                                                                </li>


                                                                <li>
                                                                    <span className="tf-nc">
                                                                        <div className="inner_main">
                                                                            <a href="#"><img src={avtarIcon} alt="user_logo" /></a>
                                                                            <div className="tooltip-add">
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Username :</p>
                                                                                    <span>admin</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Sponsor ID :</p>
                                                                                    <span>none</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Package :</p>
                                                                                    <span>1000</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Left team :</p>
                                                                                    <span>1112</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Right team :</p>
                                                                                    <span>5</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>5</p>

                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p> Left Green Team  :</p>
                                                                                    <span>16</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Right Green Team :</p>
                                                                                    <span>0</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Left Business :</p>
                                                                                    <span>112019000</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Right Business :</p>
                                                                                    <span>0</span>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <p>admin <br />Administrator</p>
                                                                    </span>
                                                                </li>
                                                            </ul>

                                                        </li>


                                                        {/* RIGHT SEC...... */}
                                                        {/* <li>
                                                            <span className="tf-nc">
                                                                <div className="inner_main">
                                                                    <a href="#"><img src={avtarIcon} alt="user_logo" /></a>
                                                                    <div className="tooltip-add">
                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                            <p>Username :</p>
                                                                            <span>admin</span>
                                                                        </div>
                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                            <p>Sponsor ID :</p>
                                                                            <span>none</span>
                                                                        </div>
                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                            <p>Package :</p>
                                                                            <span>1000</span>
                                                                        </div>
                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                            <p>Left team :</p>
                                                                            <span>1112</span>
                                                                        </div>
                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                            <p>Right team :</p>
                                                                            <span>5</span>
                                                                        </div>
                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                            <p>5</p>

                                                                        </div>
                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                            <p> Left Green Team  :</p>
                                                                            <span>16</span>
                                                                        </div>
                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                            <p>Right Green Team :</p>
                                                                            <span>0</span>
                                                                        </div>
                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                            <p>Left Business :</p>
                                                                            <span>112019000</span>
                                                                        </div>
                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                            <p>Right Business :</p>
                                                                            <span>0</span>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <p>admin <br />Administrator </p>
                                                            </span>



                                                            <ul>
                                                                <li>
                                                                    <span className="tf-nc">
                                                                        <div className="inner_main">
                                                                            <a href="#"><img src={avtarIcon} alt="user_logo" /></a>
                                                                            <div className="tooltip-add">
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Username :</p>
                                                                                    <span>admin</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Sponsor ID :</p>
                                                                                    <span>none</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Package :</p>
                                                                                    <span>1000</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Left team :</p>
                                                                                    <span>1112</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Right team :</p>
                                                                                    <span>5</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>5</p>

                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p> Left Green Team  :</p>
                                                                                    <span>16</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Right Green Team :</p>
                                                                                    <span>0</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Left Business :</p>
                                                                                    <span>112019000</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Right Business :</p>
                                                                                    <span>0</span>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <p>admin ji <br />Administrator</p>
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <span className="tf-nc">
                                                                        <div className="inner_main">
                                                                            <a href="#"><img src={avtarIcon} alt="user_logo" /></a>
                                                                            <div className="tooltip-add">
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Username :</p>
                                                                                    <span>admin</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Sponsor ID :</p>
                                                                                    <span>none</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Package :</p>
                                                                                    <span>1000</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Left team :</p>
                                                                                    <span>1112</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Right team :</p>
                                                                                    <span>5</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>5</p>

                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p> Left Green Team  :</p>
                                                                                    <span>16</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Right Green Team :</p>
                                                                                    <span>0</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Left Business :</p>
                                                                                    <span>112019000</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Right Business :</p>
                                                                                    <span>0</span>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <p>admin <br />Administrator</p>
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <span className="tf-nc">
                                                                        <div className="inner_main">
                                                                            <a href="#"><img src={avtarIcon} alt="user_logo" /></a>
                                                                            <div className="tooltip-add">
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Username :</p>
                                                                                    <span>admin</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Sponsor ID :</p>
                                                                                    <span>none</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Package :</p>
                                                                                    <span>1000</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Left team :</p>
                                                                                    <span>1112</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Right team :</p>
                                                                                    <span>5</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>5</p>

                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p> Left Green Team  :</p>
                                                                                    <span>16</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Right Green Team :</p>
                                                                                    <span>0</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Left Business :</p>
                                                                                    <span>112019000</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Right Business :</p>
                                                                                    <span>0</span>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <p>admin <br />Administrator</p>
                                                                    </span>
                                                                </li>
                                                                <li>
                                                                    <span className="tf-nc">
                                                                        <div className="inner_main">
                                                                            <a href="#"><img src={avtarIcon} alt="user_logo" /></a>
                                                                            <div className="tooltip-add">
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Username :</p>
                                                                                    <span>admin</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Sponsor ID :</p>
                                                                                    <span>none</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Package :</p>
                                                                                    <span>1000</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Left team :</p>
                                                                                    <span>1112</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Right team :</p>
                                                                                    <span>5</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>5</p>

                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p> Left Green Team  :</p>
                                                                                    <span>16</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Right Green Team :</p>
                                                                                    <span>0</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Left Business :</p>
                                                                                    <span>112019000</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Right Business :</p>
                                                                                    <span>0</span>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <p>admin <br />Administrator</p>
                                                                    </span>

                                                                    <ul>
                                                                        <li>
                                                                            <span className="tf-nc">
                                                                                <div className="inner_main">
                                                                                    <a href="#"><img src={avtarIcon} alt="user_logo" /></a>
                                                                                    <div className="tooltip-add">
                                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                                            <p>Username :</p>
                                                                                            <span>admin</span>
                                                                                        </div>
                                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                                            <p>Sponsor ID :</p>
                                                                                            <span>none</span>
                                                                                        </div>
                                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                                            <p>Package :</p>
                                                                                            <span>1000</span>
                                                                                        </div>
                                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                                            <p>Left team :</p>
                                                                                            <span>1112</span>
                                                                                        </div>
                                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                                            <p>Right team :</p>
                                                                                            <span>5</span>
                                                                                        </div>
                                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                                            <p>5</p>

                                                                                        </div>
                                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                                            <p> Left Green Team  :</p>
                                                                                            <span>16</span>
                                                                                        </div>
                                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                                            <p>Right Green Team :</p>
                                                                                            <span>0</span>
                                                                                        </div>
                                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                                            <p>Left Business :</p>
                                                                                            <span>112019000</span>
                                                                                        </div>
                                                                                        <div className="inner d-flex justify-content-between align-items-center">
                                                                                            <p>Right Business :</p>
                                                                                            <span>0</span>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <p>admin <br />Administrator</p>
                                                                            </span>

                                                                            <ul>
                                                                            <li>
                                                                    <span className="tf-nc">
                                                                        <div className="inner_main">
                                                                            <a href="#"><img src={avtarIcon} alt="user_logo" /></a>
                                                                            <div className="tooltip-add">
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Username :</p>
                                                                                    <span>admin</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Sponsor ID :</p>
                                                                                    <span>none</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Package :</p>
                                                                                    <span>1000</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Left team :</p>
                                                                                    <span>1112</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Right team :</p>
                                                                                    <span>5</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>5</p>

                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p> Left Green Team  :</p>
                                                                                    <span>16</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Right Green Team :</p>
                                                                                    <span>0</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Left Business :</p>
                                                                                    <span>112019000</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Right Business :</p>
                                                                                    <span>0</span>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <p>admin <br />Administrator</p>
                                                                    </span>
                                                                </li>
                                                                            </ul>
                                                                        </li>

                                                                        <li>
                                                                    <span className="tf-nc">
                                                                        <div className="inner_main">
                                                                            <a href="#"><img src={avtarIcon} alt="user_logo" /></a>
                                                                            <div className="tooltip-add">
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Username :</p>
                                                                                    <span>admin</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Sponsor ID :</p>
                                                                                    <span>none</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Package :</p>
                                                                                    <span>1000</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Left team :</p>
                                                                                    <span>1112</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Right team :</p>
                                                                                    <span>5</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>5</p>

                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p> Left Green Team  :</p>
                                                                                    <span>16</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Right Green Team :</p>
                                                                                    <span>0</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Left Business :</p>
                                                                                    <span>112019000</span>
                                                                                </div>
                                                                                <div className="inner d-flex justify-content-between align-items-center">
                                                                                    <p>Right Business :</p>
                                                                                    <span>0</span>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <p>admin <br />Administrator</p>
                                                                    </span>
                                                                </li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </li> */}
                                                    </ul>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Teamtree