import React from 'react';
import Decentralization from '../Assets/Imgeas/decentralization.webp'
import { Currency, title } from '../config/config';

const Information = () => {
  return (
    <div className="fxied-width-container information">
    <div className="dashboard-data-container">
      <div className="bot-page">
        <div className='row m-0 '>
            <div className='col-12 '>
                <div className='bot-page-header'>
                    <h1 className='mb-0'>Instruction <small><span>ID 20933</span></small></h1>
                </div>
            </div>
            <div className='col-12'>
                <div className='row mt-5'>
                    <div className='col-md-7 p-0'>
                       <div className='instruction__column'>
                            <div className='card instruction__left_card' id='First__intro'>
                                    <div className='card-header'>
                                        <h3>Decentralization</h3>
                                    </div>
                                    <div className='card-body'>
                                        <img src={Decentralization} alt="Decentralization/img" width="100%" height="100%" />
                                        <p className='mt-5'>What is decentralization? What are its benefits and advantages? Decentralized marketing is created on an automated contract that guarantees you maximum security and stability.</p>
                                        <p> A smart contract is an algorithm with automatic execution. It exists inside the Smart Chain blockchain*, which is one of the top cryptocurrencies. Smart contracts, like cryptocurrencies themselves, are decentralized. They work strictly according to a certain program, and once they are launched, it is impossible to change their mode of operation. The code that determines the logic of a smart contract is written in a blockchain, and all calculations are performed by millions of computers around the world. This ensures that a smart contract cannot be hacked or stopped.</p>
                                    <div className='card-content_a'>
                                        <p className='mb-0'>Blockchain is an immutable register of transactions and information, protected from any further manipulation by cryptographic methods. It is simultaneously maintained by thousands of independent computers around the world.</p>
                                    </div>
                                </div>
                            </div>

                            {/* --- new code  */}

                            <div className='card instruction__left_card' id='What___busd'>
                                    <div className='card-header'>
                                        <h3>What is a {Currency} token</h3>
                                    </div>
                                    <div className='card-body'>
                                        <p className=''>{Currency} is a cryptocurrency with a fixed exchange rate that is equal to $1. It has a fully verified code and is approved by the New York State Department of Financial Services.</p>
                                        <p>5 benefits of {Currency} for {title}:</p>
                                        <ul className='list-group list-group-numbered bg-transparent'>
                                            <li className='list-group-item bg-transparent'>Fixed Cost. Your {title} performance is no longer affected by rate fluctuations and market corrections.</li>
                                            <li className='list-group-item bg-transparent'>All the opportunities that cryptocurrency provides. {Currency} token can be exchanged for any cryptocurrency on DEX exchangers with negligible fees in a couple of clicks.</li>
                                            <li className='list-group-item bg-transparent'>Simple calculations. Registration fees, upgrades, and cycles are now specified in US Dollar equivalents.</li>
                                            <li className='list-group-item bg-transparent'>Precise planning. You can accurately track costs and estimate results without wasting time converting from one unit to another.</li>
                                            <li className='list-group-item bg-transparent'>Protection of participants' interests.{Currency} is fully backed by the US Dollar at a ratio of 1:1 and can always be exchanged for Dollars. It was created in a Paxos and Binance partnership.</li>
                                        </ul>
                                </div>
                            </div>

                       </div>

                    </div>
                    <div className='col-md-5 p-0'>
                        <div className='card instruction__right_card'>
                            <h3> road map</h3>
                            <ul className='list-group list-group-numbered bg-transparent'>
                                <li className='list-group-item bg-transparent'>
                                    <a href='#First__intro'> Decentralization </a>
                                </li>
                                <li className='list-group-item bg-transparent'>
                                <a href='#What___busd'> What is a {Currency} token </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>



        </div>
      </div>
    </div>
  </div>
  );
}

export default Information;
