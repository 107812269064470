import React, { useEffect, useState } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import LaunchIcon from '@mui/icons-material/Launch';
import { Currency, api_url, blockChain_url } from '../config/config';
import { useParams } from 'react-router-dom';
import _fetch from '../config/api';
import dateFormat from 'dateformat';
import ReactPaginate from 'react-paginate';

const StakeList = () => {
    function fillters(id) {
        var element = document.getElementById(id);
        element.classList.toggle("show-full-card");
    }

    const [incomeDatas, setincomeDatas] = useState();
    const [page, setpage] = useState(1);
    const [limit, setlimit] = useState(10);
    const [totalRecords, settotalRecords] = useState(0);
    const [searchBy, setsearchBy] = useState('hash');
    const [search, setsearch] = useState();
    const getIncomeData = async () => {
        const formData = {
        }

        var passData = "";
        if (search !== "" && search !== undefined) {
            passData = `&search=${searchBy}&value=${search}`;
        }
        const data = await _fetch(`${api_url}report/stakeList?page=${page}&limit=${limit}${passData}`, 'get', formData, {});
        if (data.status === 'success') {
            setincomeDatas(data.data.data);
            // console.log('data.users', data.totalRecord);
            // setuserInfo(data.data.data);
            settotalRecords(data.total)
            // console.log('data.datadzsfsdf dsd incomes', data.total);
        } else {
            // setregisterStatus(false);
        }
    }
    useEffect(() => {
        getIncomeData()
    }, [page, limit, searchBy, search])

    const copyToClipboard = (address) => {
        var textField = document.createElement('textarea');
        textField.innerText = address;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        // toasted.success(name + 'Address copied successfull!');
    }

    const handlePageClick = async (event) => {
        const newOffset = (event.selected + 1);
        setpage(newOffset);
    };


    return (
        <>
            <div className='fxied-width-container'>
                <div className='dashboard-data-container'>
                    <div className='partner-page'>
                        <div className='row m-0 '>
                            <div className='col-12 '>
                                <div className='partner-page-header'>
                                    <h1 className='mb-0'>Stake History</h1>
                                    <div className='fillters-button' onClick={() => fillters('show-card')}>
                                        <FilterAltIcon />
                                        Filters
                                    </div>
                                </div>
                            </div>

                            <div className='col-12 ' >
                                <div className='filters-show-card' id='show-card'>
                                    <div className='card border-0 filter-card' >
                                        <form>
                                            <div className='row m-0'>
                                                {/* <div className='col-md-4'>
                                                    <label for="sel1" class="form-label">Program</label>
                                                    <select class="form-select" id="sel1" name="sellist1" disabled>
                                                        <option></option>
                                                    </select>

                                                    <button type='submit' className='btn'>Apply fillters</button>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label for="sel1" class="form-label">Level</label>
                                                    <select class="form-select" id="sel1" name="sellist1">
                                                        <option>1</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                    </select>
                                                    <button type='submit' className='btn'>Reset fillters</button>
                                                </div> */}
                                                <div className='col-md-4'>
                                                    <div class="form-group">
                                                        <label for="search" class="form-label">Search Hash </label>
                                                        <input type="search" id='search' class="form-control" name='search' onChange={(e) => { setsearch(e.target.value) }} placeholder='Enter hash' />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            {/* table */}
                            <div className='col-12'>
                                <div className='card border-0 table-card'>
                                    <div class="table-responsiv__main">
                                        <table class="table table__main_2">
                                            <thead>
                                                <tr>
                                                    <th>Stake Date</th>
                                                    <th>Address</th>
                                                    <th>Stake</th>
                                                    <th>Return Stake</th>
                                                    <th>Months</th>
                                                    <th>total Months</th>

                                                </tr>
                                            </thead>
                                            <tbody>


                                                {incomeDatas?.map((val) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <span className='date-time' >{dateFormat(val?.staking_date, "UTC:dd-mm-yyyy hh:mm:ss TT")}</span>
                                                            </td>
                                                            <td>
                                                                <div className='d-flex'>
                                                                    <div className='user_ID'><span>{val.username}</span></div>
                                                                    <div className='btns-al'>
                                                                        <span onClick={() => { copyToClipboard(val.username) }}><CopyAllIcon /></span>
                                                                        <a href={blockChain_url + val?.hash} target='_blank'> <span><LaunchIcon /></span></a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {val.package} {Currency}
                                                            </td>
                                                            <td>
                                                                {val.return_amount} {Currency}
                                                            </td>

                                                            <td>
                                                                {val.time_period}
                                                            </td>
                                                            <td>
                                                                {val.total_time_period}
                                                            </td>

                                                        </tr>
                                                    )
                                                })}

                                                {/* <tr>
                                                    <td>
                                                        <span className='date-time' >02.10.2022 15:43</span>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex'>
                                                            <div className='user_ID'><span>0x34fc137de405a2793147c54c2ace16632a9316da</span></div>
                                                            <div className='btns-al'>
                                                                <span><CopyAllIcon /></span>
                                                                <span><LaunchIcon /></span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <a href='#' className='btn'>ID 743814</a>
                                                    </td>
                                                    <td>1</td>
                                                    <td className='text-end'>1 {Currency}	</td>
                                                    <td className='text-end'>10</td>
                                                </tr> */}

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='pagination-area'>


                                        {totalRecords > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                            breakLabel="..."
                                            nextLabel=">"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={1}
                                            pageCount={totalRecords / limit}
                                            previousLabel="<"
                                        />}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default StakeList;
