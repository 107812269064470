import React, { useEffect, useState } from 'react'
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded, PeopleOutlineRounded } from '@mui/icons-material';
import { api_url, title } from '../config/config';
import { Link, useParams } from 'react-router-dom';
import _fetch from '../config/api';


const PoolTreeX2 = () => {



    const { address } = useParams()
    const [list, setlist] = useState([])




    const getTeam = async () => {
        
        const formData = {
            'level': address
        }
        const data = await _fetch(`${api_url}activation/poolX2`, 'POST', formData, {});
        if (data.status === 'success') {
        setlist(data?.viewPool);
        // console.log('data?.data', data?.data);
        }
        //  else {
        //     // setregisterStatus(false);
        // }
    }

    useEffect(() => {
        
        getTeam()
    }, [address])


    return (
        <div className='fxied-width-container'>
            <div className='dashboard-data-container'>
                <div className='partner-page profile_page'>
                    <div className='row m-0 '>
                        <div className='col-12 '>
                            <div className='partner-page-header'>
                                <p> <span> Dashboard /</span> Tree </p>
                                <h1 className='mb-0'>{title} x2  </h1>
                            </div>
                        </div>

                        <div className='col-12'>

                            <div className='uplineCode'>
                                <div className='__upline'>
                                    <div className='__uplineData'>
                                        Upline <span> ID {list?.[0]?.username}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="directory__sec">
                                <div className='prev__button'>
                                    <Link to={"/x2/" + (address > 1 ? (Number(address) - 1) : 1)} className='button__sec' >  <KeyboardArrowLeftRounded />  {(Number(address) -1) == 0 ? "": 'Level '+(Number(address) -1)} </Link>
                                    <Link to={"/x2/" + ((Number(address) + 1) )} className='button__sec next-button-block-md'> Level {(Number(address) + 1)}  <KeyboardArrowRightRounded /> </Link>

                                </div>


                                <div className='card directory'>
                                    <div className='card-header'>
                                        <h1> ID {list?.[0]?.username}</h1>
                                        <h3>Lvl {address}</h3>
                                        {/* <div className='coin__sec'>
                                    <img src={bnb} alt='coin__img' />
                                    5
                                </div> */}
                                    </div>

                                    <div className='card-body'>
                                        <div className='card-box card-scroll-data' >


                                            <div className='id__box'>
                                                {list?.[0]?.down_id && <Link to={"/x2/" + list?.[0]?.level} className="stretched-link"></Link>}
                                                <p>{list?.[0]?.down_id}</p>
                                            </div>

                                            <div className='id__box'>
                                                {list?.[1]?.down_id && <Link to={"/x2/" + list?.[1]?.level} className="stretched-link"></Link>}
                                                <p>{list?.[1]?.down_id}</p>
                                            </div>


                                        </div>
                                    </div>

                                    <div className='card-footer'>
                                        <div className='card-footer-left'>
                                            <div className='card-user-details'>
                                                <p>Partners</p>
                                                <div className='partners_number'> <PeopleOutlineRounded />  {list?.length} </div>
                                            </div>


                                        </div>


                                    </div>
                                </div>

                                <div className='prev__button next-button-none-md'>
                                    <Link to={"/x2/" + (Number(address) + 1)} className='button__sec'>  Level {(Number(address) + 1)} <KeyboardArrowRightRounded /> </Link>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default PoolTreeX2