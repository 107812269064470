import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { api_url, logo, title } from '../../config/config';
import _fetch from '../../config/api';
import { HowToReg, Info, Instagram, PermContactCalendar, Stream, Telegram, Twitter, YouTube } from '@mui/icons-material';

const Footer = () => {

    const navigate = useNavigate();

    const DemoClickLogin = async () => {
        // e.preventDefault();

        const formData = {
            wallet_address: '0x8e3108449023742646d0D85Dd5140F6C943a38B6',
        }

        const subdata = await _fetch(`${api_url}auth/login`, 'LOGINPOST', formData, {});
        // console.log('subdata',subdata);
        if (subdata.status === "success") {
            localStorage.setItem("auth", 'true');
            localStorage.setItem("accessToken", subdata?.token);
            localStorage.setItem("previewId", false);
            navigate("/dashboard");
        } else {
            // toasted.error(subdata.message);
        }
    }

    return (
        <>
            <footer className="footer">
                <div className="container-new">
                    <div className="row justify-content-center">
                        <div className='col-lg-4'>
                            <div className='__logoSection'>
                                <h2 className="footer-heading">
                                    <Link to="/" className="logo">
                                        <img src={logo} alt="logo" width="100%" />
                                    </Link>
                                </h2>
                                <p>Real-time global events of {title} Platform</p>
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='__Links'>
                                <h5>Service</h5>
                                <ul className='nav justify-content-center mt-5 mb-5'>
                                    <li className='nav-item'>
                                        <Link className='btn' to="/registration" > <HowToReg /> Register Now </Link>
                                    </li>
                                    <li className='nav-item'>
                                        <button className='btn' onClick={DemoClickLogin} type='button' > <Stream /> click Demo </button>
                                    </li>

                                    <li className='nav-item'>
                                        <Link className='btn' to="/about" ><Info /> About </Link>
                                    </li>
                                    <li className='nav-item'>
                                        <Link className='btn' to="/contacts" > <PermContactCalendar /> Contact Us    </Link>
                                    </li>
                                </ul>
                            </div>

                        </div>
                        <div className="col-lg-4">
                            <div className='__Links'>
                                <h5>Community</h5>
                                <ul className='nav justify-content-center mt-5 mb-5'>
                                    <li className='nav-item'>
                                        <a className='btn' href='https://twitter.com/' target='_black' >  <Twitter /> twitter</a>
                                    </li>
                                    <li className='nav-item'>
                                        <a className='btn' href='https://www.youtube.com/' target='_black'>  <YouTube /> youtube </a>
                                    </li>

                                    <li className='nav-item'>
                                        <a className='btn' href='https://web.telegram.org/' target='_black' > <Telegram /> Telegram  </a>
                                    </li>

                                    <li className='nav-item'>
                                        <a className='btn' href='https://www.instagram.com/' target='_black' > <Instagram /> Instagram  </a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row mt-5 copy_right">
                    <div className="col-md-12 text-center">
                        <p className="copyright"> Copyright &copy; {(new Date().getFullYear())} | {title}. All Rights Reserved  </p>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
