import React, { useEffect, useState } from 'react';
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Link } from 'react-router-dom';
import { api_url, logo, title } from '../config/config';
import _fetch from '../config/api';







const HomeHeader = () => {

  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);




  window.scrollTo(0, document.body.scrollTop);


  var className = "header__data";
  var scrollTrigger = 60;

  window.onscroll = function () {
    if (window.scrollY >= scrollTrigger || window.pageYOffset >= scrollTrigger) {
      document.getElementsByTagName("body")[0].classList.add(className);
    } else {
      document.getElementsByTagName("body")[0].classList.remove(className);
    }
  };


  const modalToggle = () => {
    if (open === true) {
      onCloseModal(false)
    }
    else {
      onOpenModal(true)
    }

  }


  let Toggle__add__class = () => {
    const rbs = document.getElementById("toggle");
    rbs.classList.toggle("active");
  }

  const [data, setData] = useState({totalUsers: '', usersLast24Hours: "", usersThisMonth: "" });
  const getparticipants = async () => {

    const data = await _fetch(`${api_url}homepage/total-users`, 'GET', {}, {});
    if (data?.status === 'success') {
      setData(data?.data);
    } else {
    }
  }
  useEffect(() => {
    getparticipants()
  }, [])

  return (
    <>

      <header className='Home-header'>
        <div className='alert__bar'>
          <div className='alert_contin'>
            <div className='t__sms'>
              All participants <span className='t-value'>{data?.totalUsers}</span>
            </div>

            <div className='t__sms'>
              Joined in 24 hours  <span className='t-value'>{data?.usersLast24Hours}</span>
            </div>

            <div className='t__sms border-0'>
              Joined in this month  <span className='t-value'>{data?.usersThisMonth}</span>
            </div>
          </div>
        </div>
        <div className='container-header'>

          <div className='short_logo short_logo_main'>
            <Link to="/">
              {/* <img src={short_logo} alt={`${title?.toLocaleLowerCase()} Logo`} className='short__logo' /> */}
              <img src={logo} alt={`${title?.toLocaleLowerCase()} Logo`} className='Main__logo' />
            </Link>
          </div>


          <ul className='Home__header'>
            {/* <li className='nav-item'>
              <Link to="/" className='nav-link'> content us </Link>
            </li>
            <li className='nav-item'>
              <Link to="/" className='nav-link'> About </Link>
            </li> */}

            <div className='header-right Public_Header'>

              <div className='d-flex align-items-center '>
                <Link to="/login" type='button' className='btn connect-btn'> Login</Link>
                <Link to="/registration" className='btn connect-btn ms-3'>  Registration</Link>
              </div>
            </div>

          </ul>


          <div className='Menu__toggle' onClick={() => { modalToggle(); Toggle__add__class() }} >
            <div class="toggle" id='toggle'>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </header>

      {/*  */}
      <Modal open={open} classNames={{
        modal: "Header__modal",
        overlay: "Heder__overly",
        modalAnimationIn: "customEnterModalAnimation",
        modalAnimationOut: "customLeaveModalAnimation",
      }} onClose={onCloseModal} center>
        {/* <h3> {title}</h3> */}
        <div className=''>
          <ul className='nav flex-columns'>
            <li className='nav-item'>
              <Link to="/login" className='nav-link'>content us  </Link>
            </li>

            <li className='nav-item'>
              <Link to="/login" className='nav-link'>About  </Link>
            </li>

            <li className='nav-item'>
              <p className='text-center disabled' disabled="disabled"> Or  </p>
            </li>

            <li className='nav-item nav-item-two'>
              <Link to="/login" className='nav-link'>Login  </Link>
              <Link to="/Registration" className='nav-link'>Registration  </Link>
            </li>


          </ul>
        </div>
      </Modal>

    </>
  );
}

export default HomeHeader;
