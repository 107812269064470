import React, { useEffect, useState } from 'react';
import { Currency, api_url, logo, title } from '../../config/config.js'
import smartChain from '../../Assets/icon/icon-1.svg'
import bnb from '../../Assets/icon/bnb.svg'
import ksn_img from '../../Assets/icon/KSN-icon.png'
import wallet from '../../Assets/icon/wallet.svg'
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { Link } from 'react-router-dom';
import trust_platform from '../../Assets/Imgeas/trust_platform.svg'
import Metamask from '../../Assets/Imgeas/Metamask-logo.png'
import UnknownUser from '../../Assets/icon/UnknownUser.webp'
import _fetch from '../../config/api.js';








const Header = (props) => {


    const [open, setOpen] = useState(false);

    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);



    const [walletMoldal, setwalletMoldal] = useState(false);
    const onOpenwalletMoldal = () => setwalletMoldal(true);
    const onClosewalletMoldal = () => setwalletMoldal(false);


    window.scrollTo(0, document.body.scrollTop);


    var className = "header__data";
    var scrollTrigger = 60;

    window.onscroll = function () {
        if (window.scrollY >= scrollTrigger || window.pageYOffset >= scrollTrigger) {
            document.getElementsByTagName("body")[0].classList.add(className);
        } else {
            document.getElementsByTagName("body")[0].classList.remove(className);
        }
    };





    const copyToClipboard = (address) => {
        var textField = document.createElement('textarea')
        textField.innerText = address;
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        alert(address);

    }

    const [registerStatus, setregisterStatus] = useState(false);
    const [userInfo, setuserInfo] = useState({
        user_id: '',
    });

    const checkUserID = async () => {
        const formData = {
            'wallet_address': props.address,
        }
        const data = await _fetch(`${api_url}activation/checkUser`, 'post', formData, {});
        if (data.status === 'success') {
            setregisterStatus(data.data.status);
        } else {
            setregisterStatus(false);
        }
    }

    const getUserDetails = async () => {
        const formData = {
        }
        const data = await _fetch(`${api_url}user/userInfo`, 'get', formData, {});
        if (data.status === 'success') {
            // setregisterStatus(data.data.status);
            setuserInfo(data.data.data);
            // console.log('data.data', data.data);
        } else {
            // setregisterStatus(false);
        }
    }
    useEffect(() => {
        checkUserID();
        getUserDetails()
    }, [])



    return (
        <>

            <header>
                <div className='container-header'>
                    <div className='short_logo short_logo_main '>
                        <Link to="/" className='text-start'>
                            <img src={logo} alt={`${title?.toLocaleLowerCase()} Logo`} className='Main__logo' />
                        </Link>
                    </div>
                    <div className='header-right'>

                        {props.address !== null &&
                            <ul className='nav spit-area '>
                                <li className='nav-item first-button'>
                                    <a href='#' className='nav-link'>
                                        <img src={smartChain} alt="img-icon" />
                                        <span>  Smart Chain</span>
                                    </a>
                                </li>
                                <li className='nav-item'>
                                    <ul className='nav ms-1 last-area' onClick={onOpenwalletMoldal} style={{ cursor: "pointer" }}>
                                        <li className='nav-item none-item'>
                                            <a className='nav-link'>
                                                <img src={ksn_img} alt="img-icon" />
                                                {props.balance !== null &&
                                                    <span>   {parseFloat(props?.balance?.tokenBalance)?.toFixed(3)}  {Currency}</span>

                                                }
                                                {props.balance === null &&
                                                    <span className='animation__divider'></span>
                                                }
                                            </a>
                                        </li>
                                        <li className='nav-item none-item'>
                                            <a className='nav-link'>
                                                <img src={bnb} alt="img-icon" />
                                                <span> {parseFloat(props?.balance?.coinBalance)?.toFixed(3)} BNB</span>

                                            </a>
                                        </li>
                                        <li className='nav-item'>
                                            <a className='nav-link'>
                                                <img src={wallet} alt="img-icon" />
                                                <span>{props?.address?.substring(0, 5) + '....' + props?.address?.substring(props?.address?.length - 5, props?.address?.length)}  </span>

                                            </a>
                                        </li>
                                    </ul>
                                </li>

                            </ul>
                        }
                        {props.address === null &&
                            <button type='button' onClick={onOpenModal} className='btn connect-btn'>Connect Wallet</button>
                        }
                    </div>
                </div>
            </header>

            {/*  */}
            <Modal open={open} classNames={{
                modal: "modal_sec",
                modalContainer: "modal__container__sec"
            }} onClose={onCloseModal} center>

                <div className="card border-0 bot-card">
                    <h4 className='text-light mb-3'>Connect wallet</h4>
                    <div className='all__wallet'>
                        <div className="bot-bot trust_wallet">
                            <img src={trust_platform} alt='trust_platform' />
                            <div className="bot-name">
                                <h4 className="mb-0" >Trust Wallet</h4>
                                <h6 className="mb-0" >DApp in app</h6>
                            </div>
                            <a className="stretched-link" onClick={() => { props.connectWallet('trust') }} ></a>
                        </div>
                        <div className="bot-bot metaMask">
                            <img src={Metamask} alt='trust_platform' />
                            <div className="bot-name" >
                                <h4 className="mb-0">MetaMask</h4>
                                <h6 className="mb-0">Desktop / DApp in app</h6>
                            </div>
                            <a className="stretched-link" onClick={() => { props.connectWallet('metamsk') }} ></a>
                        </div>
                    </div>

                </div>
            </Modal>


            <Modal open={walletMoldal} classNames={{
                modal: "modal_sec__wallet",
                modalContainer: "modal__container__sec"
            }} onClose={onClosewalletMoldal} center>
                <div className="card border-0 wallet___card">
                    <div className='card-header mb-3'>
                        <h4 className='text-light'>Wallet</h4>
                    </div>

                    <div className='card-body card-details'>
                        <div className='card__wallet__ae'>
                            <div className='card__wallet__ae__area'>
                                <img src={wallet} alt="img-icon" />
                                <span className='Address__e'>{props?.address?.substring(0, 5) + '....' + props?.address?.substring(props?.address?.length - 5, props?.address?.length)}  </span>
                            </div>
                            <button type='button' className='btn copy_button' onClick={() => { copyToClipboard(props?.address) }}> Copy</button>
                        </div>

                        <div className='card__wallet__ae'>
                            <div className='card__wallet__ae__area'>
                                <img src={smartChain} alt="img-icon" />
                                <span>  Smart Chain</span>
                            </div>
                        </div>

                        <h6 className='divider_heading'>Current wallet balance</h6>

                        <div className='card__wallet__ae'>
                            <div className='card__wallet__ae__area'>
                                <img src={ksn_img} alt="img-icon" />
                                <span>   {parseFloat(props?.balance?.tokenBalance)?.toFixed(3)}  {Currency}</span>
                            </div>
                        </div>

                        <div className='card__wallet__ae'>
                            <div className='card__wallet__ae__area'>
                                <img src={bnb} alt="img-icon" />
                                <span> {parseFloat(props?.balance?.coinBalance)?.toFixed(3)} BNB</span>
                            </div>
                        </div>
                        <button className='btn close_button' type='button' onClick={() => { props.disconnectWallet() }}> Disconnect </button>

                    </div>

                    <div className='card-body-new card-body'>
                        <div className='user__UnknownUser'>
                            <img src={UnknownUser} alt="user_UnknownUser" />
                            <div className='ms-3'>
                                <p>{registerStatus === false ? `Register in ${Currency}` : `ID: ${userInfo?.user_id}`}</p>
                                <h6>{registerStatus === false ? `Wallet ${props?.address?.substring(0, 5) + '....' + props?.address?.substring(props?.address?.length - 5, props?.address?.length)} is not a member of ${title?.toLowerCase()}. You can use this wallet to register as a new member.` : `${props?.address?.substring(0, 5) + '....' + props?.address?.substring(props?.address?.length - 5, props?.address?.length)} is  a member of ${title?.toLowerCase()} ${Currency}. `} </h6>
                            </div>
                        </div>

                        {registerStatus === false && <div className='btn__user__new'>
                            <Link to="/registration" className='btn active-button' onClick={() => { onClosewalletMoldal() }}> Create account</Link>
                        </div>}

                        {registerStatus === true && <div className='btn__user__new'>
                            <Link to="/login" className='btn active-button' onClick={() => { onClosewalletMoldal() }}> Login to your account</Link>
                        </div>}


                    </div>

                </div>
            </Modal>




        </>
    );
}

export default Header;
