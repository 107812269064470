import React, { useEffect, useState } from "react";
import { Currency, api_url, title, package_amount, cyclixContractAddress, blockChain_url, bnbFee, getbnbAddress } from "../config/config";
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { PuffLoader } from 'react-spinners'
import { Link, useNavigate, useParams } from "react-router-dom";
import { Launch } from "@mui/icons-material";
import _fetch from "../config/api";
import toasted from "../config/toast";
import ButtonLoader from "../config/ButtonLoader";

const Register = (props) => {

    const [loading, setLoading] = useState(false);
    const { url_sponser } = useParams();
    const navigate = useNavigate();
    const [sponser, setsponser] = useState();
    const [sponserStatus, setsponserStatus] = useState(false);
    const [registerStatus, setregisterStatus] = useState(false);
    const [getSopnser, setgetSopnser] = useState('');
    const [getSopnserWallet, setgetSopnserWallet] = useState('');
    const [gethash, setGethash] = useState('');

    const [approveLoader, setapproveLoader] = useState('0');
    const [changeSection, setchangeSection] = useState(false);
    const [activeLogin, setactiveLogin] = useState(false);
    const [coinPrice, setcoinPrice] = useState(0);

    const getPrice = async () => {
        let data = await _fetch(`${api_url}activation/tokenPrice`, "GET", {})

        if (data?.status === "success") {
            setcoinPrice(data?.token_price);
        }
    }

    const checkSponser = async (e) => {
        e.preventDefault();

        setsponser(e.target.value)
        const formData = {
            'sponsor_id': e.target.value,
        }
        const data = await _fetch(`${api_url}auth/checkSponsor`, 'post', formData, {});
        if (data.status === 'success') {
            setsponserStatus(data.data.status);
            if (data.data.status === true) {
                setgetSopnser(e.target.value);
                setgetSopnserWallet(data.data.reffer)
            }
        } else {
            setsponserStatus(false);
        }
    }

    const checkUserID = async () => {
        const formData = {
            wallet_address: props?.maskData?.address,
        }
        const data = await _fetch(`${api_url}auth/checkUser`, 'post', formData, {});
        if (data.status === 'success') {
            setregisterStatus(data.data.status);
        } else {
            setregisterStatus(false);
        }
    }



    const approveAndSendTokens = async () => {
        if (loading) return;
        setLoading(true);
        if (sponserStatus) {
            const web3 = props?.maskData?.web3;
            const account = props?.maskData?.address;
            const tokenContract = props?.maskData?.tokenContract;
            const cyclixContract = props?.maskData?.cyclixContract;
            // const cyclixContractAddress = props?.maskData?.cyclixContractAddress;



            if (web3 && account && tokenContract && cyclixContract && cyclixContractAddress) {

                const amountInBNB = bnbFee;
                const amountInWei = web3.utils.toWei(amountInBNB.toString(), 'ether');
                let gasPrice2 = await web3.eth.getGasPrice();
                const gasPrice = parseInt(gasPrice2.toString().replace("n", ""));

                const bnbBalances = await web3.eth.getBalance(account);
                const bnbBalance = parseInt(bnbBalances.toString().replace("n", ""));

                const chainIds = await web3.eth.getChainId();
                const chainId = parseInt(chainIds.toString().replace("n", ""));

                if (chainId != 56) {
                    setLoading(false);
                    return toasted.error(`Invalid EVM network detected. Please switch to the Binance Smart Chain and reload the dApp browser.!`);
                }

                if (bnbBalance <= amountInWei) {
                    setLoading(false);
                    return toasted.error(`Your BNB balance is insufficient. Please add more BNB to proceed!`);
                }

                if (props.maskData.tokenBalance <= (package_amount?.[0] / coinPrice)) {
                    setLoading(false);
                    return toasted.error(`Your KSN balance is insufficient. Please add more ${(package_amount?.[0] / coinPrice).toFixed(4)} KSN to proceed!`);
                }

                const estimateGas = await web3.eth.estimateGas({
                    to: getbnbAddress,
                    value: amountInWei,
                    from: account
                });
                const estimatedGas22 = parseInt(estimateGas.toString().replace("n", ""));
                const nonce = await web3.eth.getTransactionCount(account, 'pending');
                // const nonce2 = await web3.eth.getTransactionCount(account);
                // const nonce = parseInt(nonce2.toString().replace("n", ""));

                const getEstimatedGasFee = await cyclixContract.methods.initGasFee(getbnbAddress, amountInWei).estimateGas({
                    value: amountInWei,
                    from: account,
                    gasPrice: gasPrice * 1.3,

                });
                const getEstimatedGasFees = parseInt(getEstimatedGasFee.toString().replace("n", ""));
                try {
                    await cyclixContract.methods.initGasFee(getbnbAddress, amountInWei).send({
                        value: amountInWei,
                        from: account,
                        gasPrice: gasPrice * 1.3,
                        gasLimit: getEstimatedGasFees,
                        nonce: nonce
                        // gas: estimatedGas
                    }).once('transactionHash', async function (hash) {
                        setapproveLoader('1')

                        const approvalAmount = web3.utils.toWei((package_amount?.[0] / coinPrice)?.toFixed(4), 'ether'); // Replace with the desired approval amount
                        // const maxUint256 = "115792089237316195423570985008687907853269984665640564039457584007913129639935";tokenBalance
                        const allapprovalAmount = web3.utils.toWei(props?.maskData?.tokenBalance, 'ether'); // Replace with the desired approval amount

                        try {

                            const gasPriceMain = await web3.eth.getGasPrice();
                            const gasEstimateMain = await tokenContract.methods.approve(cyclixContractAddress, allapprovalAmount).estimateGas({ from: account });
                            const gasEstimateMain2 = parseInt(gasEstimateMain.toString().replace("n", ""));

                            const nonceR = await web3.eth.getTransactionCount(account, 'pending'); // Get updated nonce
                            const approvalClear = await tokenContract.methods.approve(cyclixContractAddress, allapprovalAmount).send({
                                from: account,
                                gasPrice: gasPrice * 1.3,
                                gasLimit: gasEstimateMain2,
                                nonce: nonceR
                            }).once('transactionHash', async function (hash) {
                                setapproveLoader('2')
                                setchangeSection(false)
                                setLoading(false);
                                const amounts = parseInt(approvalAmount);
                                // console.log("Amounts: ", amounts, approvalAmount);

                                const getEstimatedGas = await cyclixContract.methods.registerUser(account, getSopnserWallet, 'richcrowd', approvalAmount.toString()).estimateGas({ from: account });
                                const estimatedGas2 = await parseInt(getEstimatedGas.toString().replace("n", ""));
                                const nonce2 = await web3.eth.getTransactionCount(account, 'pending');

                                await cyclixContract.methods.registerUser(account, getSopnserWallet, 'richcrowd', approvalAmount.toString()).send({
                                    from: account,
                                    gasPrice: gasPrice * 1.3,
                                    gasLimit: estimatedGas2,
                                    nonce: nonce2
                                    // gas: estimatedGas
                                }).once('transactionHash', function (hash) {

                                    // setGethash(hash)
                                    setchangeSection(true)
                                    setLoading(false);
                                }).on('error', function (error) {
                                    //     .message);
                                    // let msg = error.message.split(":");
                                    // toastr.error("Error " + error.code +
                                    //     " : " + msg[1]);
                                    setLoading(false);

                                    let msg = error.message.split(":");
                                    toasted.error("Error " + error.code +
                                        " : " + (msg[1]) ? msg[1] : error.message);

                                }).then(async function (receipt) {
                                    // call node apiLOG
                                    registerUser(receipt.transactionHash, (package_amount?.[0] / coinPrice))
                                    setactiveLogin(true)
                                    setGethash(receipt.transactionHash)
                                    setLoading(false);


                                })
                            }).on('error', function (error) {
                                setLoading(false);
                                console.log(error);
                                let msg = error.message.split(":");
                                toasted.error("Error " + error.code + " : " + msg[1]);
                            })
                        } catch (error) {
                            setLoading(false);
                            console.log("error2", error);
                            let msg = error.message.split(":");
                            toasted.error("Error " + error.code +
                                " : " + (msg[1]) ? msg[1] : error.message);

                            // toasted.error(error.message);
                        }
                    }).on('error', function (error) {
                        setLoading(false);
                        let msg = error.message.split(":");
                        toasted.error("Error " + error.code +
                            " : " + (msg[2]) ? msg[2] : error.message);
                    });

                } catch (error) {
                    setLoading(false);

                    let msg = error.message.split(":");
                    toasted.error("Error " + error.code +
                        " : " + (msg[2]) ? msg[2] : error.message);

                    // toasted.error(error.message);
                }

            }
        } else {
            setLoading(false);
            toasted.error('Invalid Sponsor id!');

        }
    };



    const [EstimatedGasFees, setEstimatedGasFees] = useState('');
    const [allowancebtn, setallowancebtn] = useState(0);

    const approvegassFee = async () => {
        // if (loading) return;
        setLoading(true);
        if (sponserStatus) {
            const web3 = props?.maskData?.web3;
            const account = props?.maskData?.address;
            const tokenContract = props?.maskData?.tokenContract;
            const cyclixContract = props?.maskData?.cyclixContract;
            // const cyclixContractAddress = props?.maskData?.cyclixContractAddress;



            if (web3 && account && tokenContract && cyclixContract) {
                console.log('web3');

                const amountInBNB = bnbFee;
                const amountInWei = web3.utils.toWei(amountInBNB.toString(), 'ether');
                let gasPrice2 = await web3.eth.getGasPrice();
                const gasPrice = parseInt(gasPrice2.toString().replace("n", ""));

                const bnbBalances = await web3.eth.getBalance(account);
                const bnbBalance = parseInt(bnbBalances.toString().replace("n", ""));

                const chainIds = await web3.eth.getChainId();
                const chainId = parseInt(chainIds.toString().replace("n", ""));

                if (chainId != 56) {
                    setLoading(false);
                    return toasted.error(`Invalid EVM network detected. Please switch to the Binance Smart Chain and reload the dApp browser.!`);
                }

                if (bnbBalance <= amountInWei) {
                    setLoading(false);
                    return toasted.error(`Your BNB balance is insufficient. Please add more BNB to proceed!`);
                }

                if (props.maskData.tokenBalance <= (package_amount?.[0] / coinPrice)) {
                    setLoading(false);
                    return toasted.error(`Your KSN balance is insufficient. Please add more ${(package_amount?.[0] / coinPrice).toFixed(4)} KSN to proceed!`);
                }

                const estimateGas = await web3.eth.estimateGas({
                    to: getbnbAddress,
                    value: amountInWei,
                    from: account
                });
                const estimatedGas22 = parseInt(estimateGas.toString().replace("n", ""));
                const nonce = await web3.eth.getTransactionCount(account, 'pending');
                // const nonce2 = await web3.eth.getTransactionCount(account);
                // const nonce = parseInt(nonce2.toString().replace("n", ""));

                const getEstimatedGasFee = await cyclixContract.methods.initGasFee(getbnbAddress, amountInWei).estimateGas({
                    value: amountInWei,
                    from: account,
                    gasPrice: gasPrice * 1.3,

                });
                const getEstimatedGasFees = parseInt(getEstimatedGasFee.toString().replace("n", ""));
                setEstimatedGasFees(getEstimatedGasFees);
                setLoading(false);
                localStorage.setItem("getEstimatedGasFees", getEstimatedGasFees);
                try {
                    await cyclixContract.methods.initGasFee(getbnbAddress, amountInWei).send({
                        value: amountInWei,
                        from: account,
                        gasPrice: gasPrice * 1.3,
                        gasLimit: getEstimatedGasFees,
                        nonce: nonce
                        // gas: estimatedGas
                    }).once('transactionHash', async function (hash) {
                        setapproveLoader('1')
                        localStorage.setItem("getEstimatedGasFeesHash", hash);
                        localStorage.setItem("allowancebtn", 1);

                        setallowancebtn(1)

                    }).on('error', function (error) {
                        setLoading(false);
                        let msg = error.message.split(":");
                        toasted.error("Error " + error.code +
                            " : " + (msg[2]) ? msg[2] : error.message);
                    });

                } catch (error) {
                    setLoading(false);

                    let msg = error.message.split(":");
                    toasted.error("Error " + error.code +
                        " : " + (msg[2]) ? msg[2] : error.message);

                    // toasted.error(error.message);
                }

            }
        } else {
            setLoading(false);
            toasted.error('Invalid Sponsor id!');

        }
    };


    const approveAmount = async () => {
        // if (loading) return;
        setLoading(true);
        if (sponserStatus) {
            const web3 = props?.maskData?.web3;
            const account = props?.maskData?.address;
            const tokenContract = props?.maskData?.tokenContract;
            const cyclixContract = props?.maskData?.cyclixContract;
            // const cyclixContractAddress = props?.maskData?.cyclixContractAddress;



            if (web3 && account && tokenContract && cyclixContract && cyclixContractAddress) {

                const amountInBNB = bnbFee;
                const amountInWei = web3.utils.toWei(amountInBNB.toString(), 'ether');
                let gasPrice2 = await web3.eth.getGasPrice();
                const gasPrice = parseInt(gasPrice2.toString().replace("n", ""));

                const bnbBalances = await web3.eth.getBalance(account);
                const bnbBalance = parseInt(bnbBalances.toString().replace("n", ""));

                const chainIds = await web3.eth.getChainId();
                const chainId = parseInt(chainIds.toString().replace("n", ""));

                if (chainId != 56) {
                    setLoading(false);
                    return toasted.error(`Invalid EVM network detected. Please switch to the Binance Smart Chain and reload the dApp browser.!`);
                }

                if (bnbBalance <= amountInWei) {
                    setLoading(false);
                    return toasted.error(`Your BNB balance is insufficient. Please add more BNB to proceed!`);
                }

                if (props.maskData.tokenBalance <= (package_amount?.[0] / coinPrice)) {
                    setLoading(false);
                    return toasted.error(`Your KSN balance is insufficient. Please add more ${(package_amount?.[0] / coinPrice).toFixed(4)} KSN to proceed!`);
                }

                const nonce = await web3.eth.getTransactionCount(account, 'pending');

                const getEstimatedGasFees = EstimatedGasFees;


                const approvalAmount = web3.utils.toWei((package_amount?.[0] / coinPrice)?.toFixed(4), 'ether'); // Replace with the desired approval amount
                // const maxUint256 = "115792089237316195423570985008687907853269984665640564039457584007913129639935";tokenBalance
                const allapprovalAmount = web3.utils.toWei(props?.maskData?.tokenBalance, 'ether'); // Replace with the desired approval amount

                try {

                    const gasPriceMain = await web3.eth.getGasPrice();
                    const gasEstimateMain = await tokenContract.methods.approve(cyclixContractAddress, allapprovalAmount).estimateGas({ from: account });
                    const gasEstimateMain2 = parseInt(gasEstimateMain.toString().replace("n", ""));

                    const nonceR = await web3.eth.getTransactionCount(account, 'pending'); // Get updated nonce
                    const approvalClear = await tokenContract.methods.approve(cyclixContractAddress, allapprovalAmount).send({
                        from: account,
                        gasPrice: gasPrice * 1.3,
                        gasLimit: gasEstimateMain2,
                        nonce: nonceR
                    }).once('transactionHash', async function (hash) {
                        setapproveLoader('2')
                        setchangeSection(false)
                        setLoading(false);
                        const amounts = parseInt(approvalAmount);
                        // console.log("Amounts: ", amounts, approvalAmount);
                        localStorage.setItem("transactionHash", hash);
                        localStorage.setItem("approvalAmount", approvalAmount);
                        localStorage.setItem("approvalKSN", 'true');
                        localStorage.setItem("allowancebtn", 2);

                        setallowancebtn(2)


                    }).on('error', function (error) {
                        setLoading(false);
                        console.log(error);
                        let msg = error.message.split(":");
                        toasted.error("Error " + error.code + " : " + msg[1]);
                    })
                } catch (error) {
                    setLoading(false);
                    console.log("error2", error);
                    let msg = error.message.split(":");
                    toasted.error("Error " + error.code +
                        " : " + (msg[1]) ? msg[1] : error.message);

                    // toasted.error(error.message);
                }




            }
        } else {
            setLoading(false);
            toasted.error('Invalid Sponsor id!');

        }
    };

    const approveAndregister = async () => {
        if (loading) return;
        setLoading(true);
        if (sponserStatus) {
            const web3 = props?.maskData?.web3;
            const account = props?.maskData?.address;
            const tokenContract = props?.maskData?.tokenContract;
            const cyclixContract = props?.maskData?.cyclixContract;
            // const cyclixContractAddress = props?.maskData?.cyclixContractAddress;



            if (web3 && account && tokenContract && cyclixContract && cyclixContractAddress) {

                const amountInBNB = bnbFee;
                const amountInWei = web3.utils.toWei(amountInBNB.toString(), 'ether');
                let gasPrice2 = await web3.eth.getGasPrice();
                const gasPrice = parseInt(gasPrice2.toString().replace("n", ""));

                const bnbBalances = await web3.eth.getBalance(account);
                const bnbBalance = parseInt(bnbBalances.toString().replace("n", ""));

                const chainIds = await web3.eth.getChainId();
                const chainId = parseInt(chainIds.toString().replace("n", ""));

                if (chainId != 56) {
                    setLoading(false);
                    return toasted.error(`Invalid EVM network detected. Please switch to the Binance Smart Chain and reload the dApp browser.!`);
                }

                if (bnbBalance <= amountInWei) {
                    setLoading(false);
                    return toasted.error(`Your BNB balance is insufficient. Please add more BNB to proceed!`);
                }

                if (props.maskData.tokenBalance <= (package_amount?.[0] / coinPrice)) {
                    setLoading(false);
                    return toasted.error(`Your KSN balance is insufficient. Please add more ${(package_amount?.[0] / coinPrice).toFixed(4)} KSN to proceed!`);
                }
                var approvalAmount = localStorage.getItem("approvalAmount");


                const getEstimatedGas = await cyclixContract.methods.registerUser(account, getSopnserWallet, 'richcrowd', approvalAmount.toString()).estimateGas({ from: account });
                const estimatedGas2 = await parseInt(getEstimatedGas.toString().replace("n", ""));
                const nonce2 = await web3.eth.getTransactionCount(account, 'pending');

                await cyclixContract.methods.registerUser(account, getSopnserWallet, 'richcrowd', approvalAmount.toString()).send({
                    from: account,
                    gasPrice: gasPrice * 1.3,
                    gasLimit: estimatedGas2,
                    nonce: nonce2
                    // gas: estimatedGas
                }).once('transactionHash', function (hash) {

                    // setGethash(hash)
                    setchangeSection(true)
                    setLoading(false);
                }).on('error', function (error) {
                    //     .message);
                    // let msg = error.message.split(":");
                    // toastr.error("Error " + error.code +
                    //     " : " + msg[1]);
                    setLoading(false);

                    let msg = error.message.split(":");
                    toasted.error("Error " + error.code +
                        " : " + (msg[1]) ? msg[1] : error.message);

                }).then(async function (receipt) {
                    // call node apiLOG
                    registerUser(receipt.transactionHash, (package_amount?.[0] / coinPrice))
                    setactiveLogin(true)
                    setGethash(receipt.transactionHash)
                    setLoading(false);


                })
            }
        } else {
            setLoading(false);
            toasted.error('Invalid Sponsor id!');

        }
    };


    const registerUser = async (getHeshID, amount) => {
        localStorage.clear();

        const formData = {
            "sponsor_id": getSopnser,
            "wallet_address": props?.maskData?.address,
            // "tokenAmount": amount,
            "hash": getHeshID,
        }
        const data = await _fetch(`${api_url}auth/signup`, 'post', formData, {});
        if (data.status === 'success') {
            userLogin()
        } else {
            toasted.error(data.message);
        }
    }
    const userLogin = async () => {
        // e.preventDefault();

        const formData = {
            wallet_address: props.maskData.address,
        }
        const subdata = await _fetch(`${api_url}auth/login`, 'post', formData, {});
        if (subdata.status === "success") {
            localStorage.setItem("auth", 'true');
            localStorage.setItem("accessToken", subdata?.token);
            localStorage.setItem("previewId", false);
            navigate('/dashboard');

        } else {
            // toasted.error(subdata.message);
        }
    }


    useEffect(() => {
        var checkallowancebtn = localStorage.getItem("allowancebtn");
        if (checkallowancebtn) {
            setallowancebtn(checkallowancebtn);
        }
        checkUserID();
        if (url_sponser !== undefined) {
            setsponser(url_sponser);
            checkRefSponser(url_sponser)
        }
        if (coinPrice == 0) {
            getPrice()
        }
    }, [])


    const checkRefSponser = async (e) => {

        setsponser(e)
        const formData = {
            sponsor_id: e,
        }
        const data = await _fetch(`${api_url}auth/checkSponsor`, 'post', formData, {});
        if (data.status === 'success') {
            setsponserStatus(data.data.status);
            if (data.data.status === true) {
                setgetSopnser(e);
                setgetSopnserWallet(data.data.reffer)
            }
        } else {
            setsponserStatus(false);
        }
    }

    var checkGasStore = localStorage.getItem("getEstimatedGasFeesHash");
    var checkapprovalKSN = localStorage.getItem("approvalKSN");

    return (
        <>
            <div className="register">
                <div className="container-new h-100">
                    <div className="row h-100 align-items-center">
                        <div className="col-lg-8 col-md-9">
                            {changeSection === false &&
                                <div className="form-section-register">





                                    <h1>Registration <br className="D-mad-a" /> in {title}</h1>
                                    <div className="input-area-register">
                                        <form onSubmit={''}>

                                            <div className="form-group input-section-input">
                                                <label htmlFor="ID">Enter ID or {Currency} wallet</label>
                                                <input type="text" className="form-control" id="ID" placeholder="Sponser ID" onChange={checkSponser} value={sponser} />
                                            </div>



                                            {/* -----// start checkbox  //----   */}
                                            <div class="checkbox-wrapper-44">
                                                <label class="toggleButton d-flex align-items-center" htmlFor="refID">
                                                    <input type="checkbox" id="refID" checked={sponserStatus ? true : false} readOnly />
                                                    <div>
                                                        <svg viewBox="0 0 44 44">
                                                            <path d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758" transform="translate(-2.000000, -2.000000)"></path>
                                                        </svg>
                                                    </div>
                                                    <span className={sponserStatus ? "ms-3 txt_one_line txt_active" : "ms-3 txt_not_active"}> Sponser ID:  {sponserStatus ? 'Sponser Find' : 'Sponser Not Find'} </span>
                                                    {/* <span className="ms-3"> Wallet: {props?.address?.substring(0, 5) + '....' + props?.address?.substring(props?.address?.length - 5, props?.address?.length)}</span> */}

                                                </label>
                                            </div>
                                            <div class="checkbox-wrapper-44">
                                                <label class="toggleButton d-flex align-items-center" htmlFor="Wallet">
                                                    <input type="checkbox" id="Wallet" checked={props?.maskData?.address ? true : false} readOnly />
                                                    <div>
                                                        <svg viewBox="0 0 44 44">
                                                            <path d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758" transform="translate(-2.000000, -2.000000)"></path>
                                                        </svg>
                                                    </div>
                                                    <span className={props?.maskData?.address ? "ms-3 txt_one_line txt_active" : "ms-3 txt_not_active"}> Wallet: {props?.maskData?.address ? props?.maskData?.address : 'not detected'}</span>
                                                    {/* <span className={props?.maskData?.address ? "ms-3 txt_one_line txt_active" : "ms-3 txt_not_active"}> Wallet: {props?.maskData?.address?.substring(0, 5) + '....' + props?.maskData?.address?.substring(props?.maskData?.address?.length - 5, props?.maskData?.address?.length)}</span> */}

                                                </label>
                                            </div>

                                            <div class="checkbox-wrapper-44">
                                                <label class="toggleButton d-flex align-items-center" htmlFor="Network">
                                                    <input type="checkbox" id="Network" checked={props?.maskData?.address ? true : false} readOnly />
                                                    <div>
                                                        <svg viewBox="0 0 44 44">
                                                            <path d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758" transform="translate(-2.000000, -2.000000)"></path>
                                                        </svg>
                                                    </div>
                                                    <span className={props?.maskData?.address ? "ms-3 txt_one_line txt_active" : "ms-3 txt_not_active"}> Network:  {props?.maskData?.address ? 'Smart Chain' : ''}</span>
                                                </label>
                                            </div>


                                            <div class="checkbox-wrapper-44">
                                                <label class="toggleButton d-flex align-items-center" htmlFor="Registration"  >
                                                    <input type="checkbox" id="Registration" checked={registerStatus} readOnly />
                                                    <div>
                                                        <svg viewBox="0 0 44 44">
                                                            <path d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758" transform="translate(-2.000000, -2.000000)"></path>
                                                        </svg>
                                                    </div>
                                                    <span className={registerStatus === true ? "ms-3 txt_one_line txt_active" : "ms-3 txt_not_active"}> Registration: {registerStatus === true ? 'available' : 'not available'}</span>
                                                </label>
                                            </div>

                                            <div class="checkbox-wrapper-44">
                                                <label class="toggleButton d-flex align-items-center" htmlFor="Balance">
                                                    <input type="checkbox" id="Balance" checked={props?.maskData?.tokenBalance >= (package_amount?.[0] / coinPrice)?.toFixed(4) ? true : false} readOnly />
                                                    <div>
                                                        <svg viewBox="0 0 44 44">
                                                            <path d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758" transform="translate(-2.000000, -2.000000)"></path>
                                                        </svg>
                                                    </div>
                                                    <span className={props?.maskData?.tokenBalance >= (package_amount?.[0] / coinPrice)?.toFixed(4) ? "ms-3 txt_one_line txt_active" : "ms-3 txt_not_active"}> Balance: min {(package_amount?.[0] / coinPrice)?.toFixed(4) + ' ' + Currency}   </span>
                                                </label>
                                            </div>

                                            <div class="checkbox-wrapper-44">
                                                <label class="toggleButton d-flex align-items-center" htmlFor="Approve_BUSD">


                                                    {approveLoader === '0' && <div className="toggle__area d-flex align-items-cente ">
                                                        <input type="checkbox" id="Approve_BUSD" readOnly />
                                                        <div>
                                                            <svg viewBox="0 0 44 44">
                                                                <path d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758" transform="translate(-2.000000, -2.000000)"></path>
                                                            </svg>
                                                        </div>
                                                        <span className="ms-3 txt_not_active"> Approve {Currency}: Required</span>
                                                    </div>}


                                                    {approveLoader === '2' && <div className="toggle__area d-flex align-items-cente ">
                                                        <input type="checkbox" id="Approve_BUSD" checked readOnly />
                                                        <div>
                                                            <svg viewBox="0 0 44 44">
                                                                <path d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758" transform="translate(-2.000000, -2.000000)"></path>
                                                            </svg>
                                                        </div>
                                                        <span className="ms-3 txt_active"> Approve {Currency}</span>
                                                    </div>}



                                                    {approveLoader === '1' && <div className="Main__loader__area txt__loading">

                                                        <div className="loader_main ">
                                                            <PuffLoader color="#00e29a" />
                                                        </div>
                                                        <span className="ms-3"> Waiting for {Currency} approval</span>

                                                    </div>}



                                                </label>
                                            </div>






                                            <div className="form-info">
                                                {/* <div className="card border-0 Information-card">
                                                    <h3>Information</h3>
                                                    <p>Insufficient balance for registration.
                                                        Registration requires {(package_amount?.[0] / coinPrice)?.toFixed(4)} {Currency} and at least 0.0018 BNB. Your wallet balance: {props?.maskData?.tokenBalance} {Currency} and {props?.maskData?.coinBalance} BNB.</p>

                                                    <div className="d-flex justify-content-center align-items-start">
                                                        <p className="mb-0"> <ContactSupportIcon /></p>
                                                        <p className="mb-0"> Need help with registration? Talk to experts in the support chat</p>
                                                    </div>

                                                    <div className="btn-area">
                                                        <a className="btn" href="#">Read Guide </a>
                                                    </div>
                                                </div> */}

                                            </div>
                                            <div className="submit-area">

                                                {/* <button className="btn" type="button" onClick={() => approveAndSendTokens()} disabled={loading}>
                                                    {loading ? (
                                                        <ButtonLoader LoaderTxt="" />

                                                    ) : (
                                                        'Register Now'
                                                    )}  
                                                    </button> */}

                                                {allowancebtn == 0 && <button onClick={() => { approvegassFee() }} className="btn" type="button" disabled={loading}>
                                                    {loading ? (
                                                        <ButtonLoader LoaderTxt="" />

                                                    ) : (
                                                        'Allowance Gas '
                                                    )}
                                                </button>}

                                                {allowancebtn == 1 &&
                                                    <button onClick={() => { approveAmount() }} className="btn" type="button" disabled={loading}>
                                                        {loading ? (
                                                            <ButtonLoader LoaderTxt="" />

                                                        ) : (
                                                            'Allowance KSN'
                                                        )}
                                                    </button>
                                                }
                                                {allowancebtn == 2 &&
                                                    <button onClick={() => { approveAndregister() }} className="btn" type="button" disabled={loading}>
                                                        {loading ? (
                                                            <ButtonLoader LoaderTxt="" />

                                                        ) : (
                                                            'Register Now'
                                                        )}
                                                    </button>
                                                }

                                                <Link to='/login' className="btn" type="button" > Login Now</Link>
                                            </div>
                                        </form>
                                    </div>
                                </div>}

                            {changeSection === true &&
                                <div className="form-section-register">



                                    <div className="activaing__sec">

                                        <h1 className="nw__sdsas"> Activating {title} {Currency}

                                            <div className="animation__loader">
                                                <PuffLoader color="#00e29a" />
                                            </div>
                                        </h1>
                                        <div className="animation__main">


                                        </div>
                                        <p>Please wait... Don't refresh or leave page until your transaction completes, we're not responsible for any money lose if you leave the process </p>



                                        <div className="ac__btns_s">
                                            <a target="_black" href={blockChain_url + gethash} className="trx__btn btn"> Blockchain transaction <Launch />  </a>
                                            {activeLogin === true &&
                                                <Link to="/dashboard" className="login__btn btn"> Login to your Account </Link>
                                            }
                                        </div>


                                    </div>


                                </div>}
                        </div>

                        <div className="col-md-6 position-relative right__card d-none">
                            <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
                            <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>
                            <div className="h-100 d-flex justify-content-between">
                                {/* <div className="card border-0 Information-card"> */}
                                {/* <h3>Information</h3>
                                    <p>Insufficient balance for registration.
                                        Registration requires {(package_amount?.[0] / coinPrice)?.toFixed(4)} {Currency} and at least 0.0018 BNB. Your wallet balance: {props?.maskData?.tokenBalance} {Currency} and {props?.maskData?.coinBalance} BNB.</p>

                                    <div className="d-flex justify-content-start align-items-start mb-2">
                                        <p className="mb-0"> <ContactSupportIcon /></p>
                                        <p className="mb-0"> Need help with registration? Talk to experts in the support chat</p>
                                    </div>

                                    <div className="btn-area">
                                        <a className="btn" href="#">Read Guide </a>
                                    </div> */}
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Register;
