import React, { useState, useEffect } from 'react';
import "../Assets/scss/Soon.scss";


function CountdownTimer() {
  // Set the target date to May 20th, 5 p.m.
  const targetDate = new Date('2024-05-20T17:00:00');
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    // Cleanup function to clear the interval when component unmounts
    return () => clearInterval(intervalId);
  }, []);

  function calculateTimeRemaining() {
    const now = new Date();
    const difference = targetDate - now;

    if (difference > 0) {
      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);
      return { days, hours, minutes, seconds };
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  }

  return (
    <div className='__Launching'>
      <h4>Launching Date</h4>
      <div className='__date'>
        <p>Days  <span>{timeRemaining.days}</span></p>
        <p>Hours <span>{timeRemaining.hours}</span></p>
        <p>Minutes <span>{timeRemaining.minutes}</span></p>
        <p>Seconds <span>{timeRemaining.seconds}</span></p>
      </div>
    </div>
  );
}

export default CountdownTimer;
