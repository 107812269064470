import { CopyAll } from '@mui/icons-material';
import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Currency, cyclixContractAddress, title } from '../config/config';
import toasted from '../config/toast';


const Partner = () => {


  var ContractAdreess = cyclixContractAddress;

  const copyToClipboard = (address) => {

    var textField = document.createElement('textarea');
    textField.innerText = address;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    toasted.success('Contract address copied!');
  }

  return (
    <section className='__partner'>
      <div className='container-main'>
        <div className='__partner__headering'>
          <h3 className='heading__card'>Partner results</h3>
          <h5>All data is stored in the blockchain in the public domain and can be verified!</h5>
        </div>

        <div className='_partnerBody'>
          {/* <Tabs>
            <TabList>
              <Tab>{Currency}</Tab>
              <Tab> TRON</Tab>
            </TabList>

            <TabPanel> */}
              <div className='__partnerData'>
                <div className='row'>
                  <div className='col-12'>
                    <ul className=''>
                      <li> {title} Contract address</li>
                      <li> <div className='address'><span>{ContractAdreess}</span> <button type='button' onClick={() => { copyToClipboard(ContractAdreess) }} > <CopyAll />  </button> </div></li>
                    </ul>
                  </div>
                  {/* <div className='col-md-4'>
                    <h2>1 697 232 <sup className='__value text-success'>+590</sup></h2>
                    <p>Accounts count</p>
                  </div>

                  <div className='col-md-4'>
                    <h2>22 537 <sup className='__value text-danger'>-1</sup></h2>
                    <p>Total result, {Currency}</p>
                  </div>

                  <div className='col-md-4'>
                    <h2>148 260 561<sup className='__value text-success'>+11 125</sup></h2>
                    <p>Total result, {Currency}</p>
                  </div> */}
                </div>
              </div>
            {/* </TabPanel> */}
            {/* <TabPanel>
              <div className='__partnerData'>
                <div className='row'>
                  <div className='col-12'>
                    <h2> Coming Soon</h2>
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs> */}
        </div>
      </div>
    </section>
  );
}

export default Partner;
