import React, { useEffect, useState } from 'react'
import bnb from '../Assets/icon/bnb.svg'
import { AccountBalanceWalletRounded, CachedRounded, KeyboardArrowLeftRounded, KeyboardArrowRightRounded, PeopleOutlineRounded } from '@mui/icons-material';
import { Currency, api_url } from '../config/config';
import { Link, useParams } from 'react-router-dom';
import _fetch from '../config/api';



const DirectTree = () => {


    const { address } = useParams()
    const [list, setlist] = useState(10)
    const [offset, setoffset] = useState(0)




    const getTeam = async () => {
        const formData = {
        }
        const data = await _fetch(`${api_url}report/directTree?username=${address}&offset=${offset}`, 'get', formData, {});
        // if (data.status === 'success') {
        setlist(data?.data);
        // console.log('data?.data', data?.data);
        // } else {
        //     // setregisterStatus(false);
        // }
    }

    useEffect(() => {
        getTeam()
    }, [address, offset])

    return (
        <>
            <div className='fxied-width-container'>
                <div className='dashboard-data-container'>
                    <div className='partner-page profile_page'>
                        <div className='row m-0 '>
                            <div className='col-12 '>
                                <div className='partner-page-header'>
                                    <p> <span> Dashboard /</span> Direct </p>
                                    <h1 className='mb-0'>Direct Tree</h1>
                                </div>
                            </div>

                            <div className='col-12'>

                                <div className="directory__sec">
                                    <div className='prev__button'>
                                        <button type='button' onClick={() => { offset > 0 ? setoffset(offset - 1) : setoffset(0) }} className='button__sec' > <KeyboardArrowLeftRounded /></button>
                                        <button type='button' onClick={() => { offset > 0 ? setoffset(offset - 1) : setoffset(0) }} className='button__sec next-button-block-md'>   <KeyboardArrowRightRounded /> </button>

                                    </div>


                                    <div className='card directory'>
                                        <div className='card-header'>
                                            {/* <h3>Lvl 1</h3> */}
                                            <h1> ID {list?.userinfo?.username}</h1>

                                            {/* <div className='coin__sec'>
                                                <img src={bnb} alt='coin__img' />
                                                5
                                            </div> */}
                                        </div>

                                        <div className='card-body'>
                                            <div className='card-box'>


                                                <div className='id__box'>
                                                    {list?.data?.[0]?.username && <Link to={"/direct-tree/" + list?.data?.[0]?.username} className="stretched-link"></Link>}
                                                    <p>{list?.data?.[0]?.username}</p>
                                                </div>

                                                <div className='id__box'>
                                                    {list?.data?.[1]?.username && <Link to={"/direct-tree/" + list?.data?.[1]?.username} className="stretched-link"></Link>}
                                                    <p>{list?.data?.[1]?.username}</p>
                                                </div>
                                                <div className='id__box'>
                                                    {list?.data?.[2]?.username && <Link to={"/direct-tree/" + list?.data?.[2]?.username} className="stretched-link"></Link>}
                                                    <p>{list?.data?.[2]?.username}</p>
                                                </div>

                                            </div>
                                        </div>

                                        <div className='card-footer'>
                                            <div className='card-footer-left'>
                                                <div className='card-user-details'>
                                                    <p>Partners</p>
                                                    <div className='partners_number'> <PeopleOutlineRounded />  {list?.data?.length} </div>
                                                </div>

                                                {/* <div className='card-user-details ms-md-5'>
                                                    <p>Cycles</p>
                                                    <div className='partners_number'> <CachedRounded />  8243 </div>
                                                </div> */}

                                            </div>

                                            {/* <div className='card-footer-right'>
                                                <div className='card-user-details'>
                                                    <p>Total level revenue</p>
                                                    <div className='partners_number'> <AccountBalanceWalletRounded />  1239821 {Currency} </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>

                                    <div className='prev__button next-button-none-md'>
                                        <button type="button" onClick={() => setoffset(offset + 1)} className='button__sec'>   <KeyboardArrowRightRounded /> </button>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DirectTree