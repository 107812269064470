import React, { useEffect, useState } from 'react'
import {  KeyboardArrowLeftRounded, KeyboardArrowRightRounded, PeopleOutlineRounded } from '@mui/icons-material';
import { api_url, title } from '../config/config';
import { Link, useParams } from 'react-router-dom';
import _fetch from '../config/api';



const PoolTree = () => {


    const { address } = useParams()
    const [list, setlist] = useState(10)




    const getTeam = async () => {
        const formData = {
        }
        const data = await _fetch(`${api_url}report/poolTree?username=${address}`, 'get', formData, {});
        // if (data.status === 'success') {
        setlist(data?.data);
        // console.log('data?.data', data?.data);
        // } else {
        //     // setregisterStatus(false);
        // }
    }

    useEffect(() => {
        getTeam()
    }, [address])

    return (
        <>
            <div className='fxied-width-container'>
                <div className='dashboard-data-container'>
                    <div className='partner-page profile_page'>
                        <div className='row m-0 '>
                            <div className='col-12 '>
                                <div className='partner-page-header'>
                                    <p> <span> Dashboard /</span> Tree </p>
                                    <h1 className='mb-0'>{title} x3  </h1>
                                </div>
                            </div>

                            <div className='col-12'>

                            <div className='uplineCode'>
                                <div className='__upline'>
                                    <div className='__uplineData'>
                                        Upline <span> ID 1</span>
                                    </div>
                                </div>
                            </div>

                                <div className="directory__sec">
                                    <div className='prev__button'>
                                        <Link to={"/pool-tree/" + (list?.data?.preview_id ? list?.data?.preview_id : address)} className='button__sec' > <KeyboardArrowLeftRounded /> Level 1</Link>
                                        <Link to={"/pool-tree/" + (list?.data?.preview_id ? list?.data?.preview_id : address)} className='button__sec next-button-block-md'>  Level 2  <KeyboardArrowRightRounded /> </Link>

                                    </div>


                                    <div className='card directory'>
                                        <div className='card-header'>
                                            <h1> ID {list?.userinfo?.user_id}</h1>
                                            <h3>Lvl 1</h3>
                                            {/* <div className='coin__sec'>
                                                <img src={bnb} alt='coin__img' />
                                                5
                                            </div> */}
                                        </div>

                                        <div className='card-body'>
                                            <div className='card-box card-scroll-data' >


                                                <div className='id__box'>
                                                    {list?.data?.[0]?.user_id && <Link to={"/pool-tree/" + list?.data?.[0]?.username} className="stretched-link"></Link>}
                                                    <p>{list?.data?.[0]?.user_id}</p>
                                                </div>

                                                <div className='id__box'>
                                                    {list?.data?.[1]?.user_id && <Link to={"/pool-tree/" + list?.data?.[1]?.username} className="stretched-link"></Link>}
                                                    <p>{list?.data?.[1]?.user_id}</p>
                                                </div>
                                                
                                                <div className='id__box'>
                                                    {list?.data?.[2]?.user_id && <Link to={"/pool-tree/" + list?.data?.[2]?.username} className="stretched-link"></Link>}
                                                    <p>{list?.data?.[2]?.user_id}</p>
                                                </div>
{/*                                                 
                                                <div className='id__box'>
                                                    {list?.data?.[3]?.user_id && <Link to={"/pool-tree/" + list?.data?.[3]?.username} className="stretched-link"></Link>}
                                                    <p>{list?.data?.[3]?.user_id}</p>
                                                </div>
                                                <div className='id__box'>
                                                    {list?.data?.[4]?.user_id && <Link to={"/pool-tree/" + list?.data?.[4]?.username} className="stretched-link"></Link>}
                                                    <p>{list?.data?.[4]?.user_id}</p>
                                                </div>
                                                <div className='id__box'>
                                                    {list?.data?.[5]?.user_id && <Link to={"/pool-tree/" + list?.data?.[5]?.username} className="stretched-link"></Link>}
                                                    <p>{list?.data?.[5]?.user_id}</p>
                                                </div>
                                                <div className='id__box'>
                                                    {list?.data?.[6]?.user_id && <Link to={"/pool-tree/" + list?.data?.[6]?.username} className="stretched-link"></Link>}
                                                    <p>{list?.data?.[6]?.user_id}</p>
                                                </div>
                                                <div className='id__box'>
                                                    {list?.data?.[7]?.user_id && <Link to={"/pool-tree/" + list?.data?.[7]?.username} className="stretched-link"></Link>}
                                                    <p>{list?.data?.[7]?.user_id}</p>
                                                </div>
                                                <div className='id__box'>
                                                    {list?.data?.[8]?.user_id && <Link to={"/pool-tree/" + list?.data?.[8]?.username} className="stretched-link"></Link>}
                                                    <p>{list?.data?.[8]?.user_id}</p>
                                                </div>
                                                <div className='id__box'>
                                                    {list?.data?.[9]?.user_id && <Link to={"/pool-tree/" + list?.data?.[9]?.username} className="stretched-link"></Link>}
                                                    <p>{list?.data?.[9]?.user_id}</p>
                                                </div> */}







                                                {/* <div className='id__box'>
                                                    <p>100011</p>
                                                </div>
                                                <div className='id__box'>
                                                    <p>100011</p>
                                                </div>
                                                <div className='id__box'>
                                                    <p>100011</p>
                                                </div>
                                                <div className='id__box'>
                                                    <p>100011</p>
                                                </div>
                                                <div className='id__box'>
                                                    <p>100011</p>
                                                </div>
                                                <div className='id__box'>
                                                    <p>100011</p>
                                                </div>
                                                <div className='id__box'>
                                                    <p>100011</p>
                                                </div>
                                                <div className='id__box'>
                                                    <p>100011</p>
                                                </div>
                                                <div className='id__box'>
                                                    <p>100011</p>
                                                </div>

                                                <div className='id__box'>
                                                    <p>100011</p>
                                                </div>
                                                <div className='id__box'>
                                                    <p>100011</p>
                                                </div> */}
                                            </div>
                                        </div>

                                        <div className='card-footer'>
                                            <div className='card-footer-left'>
                                                <div className='card-user-details'>
                                                    <p>Partners</p>
                                                    <div className='partners_number'> <PeopleOutlineRounded />  {list?.data?.length} </div>
                                                </div>

                                                {/* <div className='card-user-details ms-md-5'>
                                                    <p>Cycles</p>
                                                    <div className='partners_number'> <CachedRounded />  8243 </div>
                                                </div> */}

                                            </div>

                                            {/* <div className='card-footer-right'>
                                                <div className='card-user-details'>
                                                    <p>Total level revenue</p>
                                                    <div className='partners_number'> <AccountBalanceWalletRounded />  1239821 {Currency} </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>

                                    <div className='prev__button next-button-none-md'>
                                        <Link to={"/pool-tree/" + (list?.data?.next_id ? list?.data?.next_id : address)} className='button__sec'> Level 1  <KeyboardArrowRightRounded />  </Link>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PoolTree