import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Currency, api_url, cyclixContractAddress, title } from '../config/config';
import Fade from 'react-reveal/Fade';
import InfoIcon from '@mui/icons-material/Info';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import _fetch from '../config/api';
import DashbordTable from '../demodashboard/DashbordTable';
import toasted from '../config/toast';


const Landing = (props) => {
    const navigate = useNavigate();
    const [siteDetails, setsiteDetails] = useState();
    const [registerStatus, setregisterStatus] = useState(false);
    const [userInfo, setuserInfo] = useState({
        user_id: '',
    });
    const [getTransactoin, setgetTransactoin] = useState([]);
    const [limit, setlimit] = useState(10)


    const userLogin = async () => {
        // e.preventDefault();

        const formData = {
            wallet_address: props?.address,
        }
        const subdata = await _fetch(`${api_url}auth/login`, 'LOGINPOST', formData, {});
        // console.log('subdata',subdata);
        if (subdata.status === "success") {
            localStorage.setItem("auth", 'true');
            localStorage.setItem("accessToken", subdata?.token);
            localStorage.setItem("previewId", 'false');
            getUserDetails()
            navigate("/dashboard");
        } else {
            // toasted.error(subdata.message);
        }
    }

    const userClickLogin = async () => {
        // e.preventDefault();

        const formData = {
            wallet_address: props?.address,
        }
        const subdata = await _fetch(`${api_url}auth/login`, 'LOGINPOST', formData, {});
        // console.log('subdata',subdata);
        if (subdata.status === "success") {
            localStorage.setItem("auth", 'true');
            localStorage.setItem("accessToken", subdata?.token);
            localStorage.setItem("previewId", 'false');
            getUserDetails()
            navigate("/dashboard");
        } else {
            // toasted.error(subdata.message);
        }
    }
    const [previewID, setpreviewID] = useState(10)

    const previewUserClickLogin = async () => {
        // e.preventDefault();
        if (previewID.length > 9) {
            var formData = {
                wallet_address: previewID,
            }
        } else {
            var formData = {
                username: previewID,
            }
        }
        const subdata = await _fetch(`${api_url}auth/loginView`, 'LOGINPOST', formData, {});
        if (subdata.status === "success") {
            localStorage.setItem("auth", 'true');
            localStorage.setItem("accessToken", subdata?.token);
            localStorage.setItem("previewId", 'true');
            getUserDetails()
            navigate("/dashboard");
        } else {
            toasted.error(subdata.message);
        }
    }

    const DemoClickLogin = async () => {
        // e.preventDefault();

        const formData = {
            username: 'RichCrowd',
        }
        const subdata = await _fetch(`${api_url}auth/loginView`, 'LOGINPOST', formData, {});
        // console.log('subdata',subdata);
        if (subdata.status === "success") {
            localStorage.setItem("auth", 'true');
            localStorage.setItem("accessToken", subdata?.token);
            localStorage.setItem("previewId", 'true');
            getUserDetails()
            navigate("/dashboard");
        } else {
            // toasted.error(subdata.message);
        }
    }

    const [RegisterdID, serRegisterdID] = useState('');
    const checkUserID = async () => {
        const formData = {
            wallet_address: props.address,
        }
        const data = await _fetch(`${api_url}activation/checkUser`, 'post', formData, {});
        if (data.status === 'success') {
            setregisterStatus(true);
            serRegisterdID(data?.username);
        } else {
            setregisterStatus(false);
        }
    }

    const getUserDetails = async () => {
        const formData = {
        }
        const data = await _fetch(`${api_url}user/userInfo`, 'get', formData, {});
        if (data.status === 'success') {
            // setregisterStatus(data.data.status);
            setuserInfo(data.data.data);
            // localStorage.setItem("user", JSON.stringify(data?.data?.data));

            // console.log('data.data', data.data);
        } else {
            // setregisterStatus(false);
        }
    }




    const getSiteDetails = async () => {
        const formData = {
        }
        const data = await _fetch(`${api_url}user/allDetails`, 'get', formData, {});
        // if (data.status === 'success') {
        setsiteDetails(data?.result);
        // } else {
        //     // setregisterStatus(false);
        // }
    }
    const getTransaction = async () => {
        const formData = {
        }
        const data = await _fetch(`${api_url}report/incomeLedger?limit=${limit}`, 'get', formData, {});
        // if (data.status === 'success') {
        setgetTransactoin(data?.users);
        // } else {
        //     // setregisterStatus(false);
        // }
    }

    useEffect(() => {
        checkUserID();
        // getUserDetails()
        getSiteDetails()
        getTransaction();
        getTotalUsersData();

        var auth = localStorage.getItem("auth");
        if (auth !== true) {
            // userLogin()
        }
    }, [])

    const [allUsers, setAllUsers] = useState({totalUsers: '', usersLast24Hours: "", usersThisMonth: "", TotalIncome: "", LastDayIncome: "", zeroLastDayIncome: "", zeroTotalIncome: "", TotalTurnover: '', LastDayTurnover: "" });
    const getTotalUsersData = async() => {

        let allData = await _fetch(`${api_url}homepage/total-users`, "GET", {})

        if (allData?.status === "success") {
            setAllUsers(allData?.data);
        }
    }

    const copyToClipboard = (address, err) => {
        var textField = document.createElement('textarea');
        textField.innerText = address;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        toasted.success(err);
    }
    return (
        <>

            <div className='home-sec header-top '>
                <div className='container-new'>
                    <div className='row'>
                        <div className='col-12'>
                            <div className='card border-0'>
                                <Fade left>

                                    <h1 className='heading' > {registerStatus === false ? `Register in ${Currency}` : `ID: ${RegisterdID}`}</h1>


                                    <p className='pragraph' >{registerStatus === false ? `Wallet ${props?.address ? props?.address?.substring(0, 5) + '....' + props?.address?.substring(props?.address?.length - 5, props?.address?.length) : ''} is not a member of ${title}. You can use this wallet to register as a new member.` : `${props?.address ? props?.address?.substring(0, 5) + '....' + props?.address?.substring(props?.address?.length - 5, props?.address?.length) : ''} is  a member of ${title} ${Currency}. `} </p>

                                    <div className='__lef'>
                                        <div className='btn-home-sec'>

                                            {registerStatus === false &&
                                                <Link to='/registration' className='btn'> Register Now </Link>
                                            }

                                            {registerStatus === true && <button type='button' onClick={userClickLogin} className='btn'>

                                                Login to your account</button>}

                                            <div className='demo__Link'>
                                                <h6>Don’t know any ID?</h6>

                                                <div className=''>
                                                    <button className='btn btn-2' onClick={DemoClickLogin}> Check demo</button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='__loginBgs'>
                                            <button type='button' className='btn'>Login</button>
                                        </div> */}
                                    </div>
                                </Fade>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Account preview */}

            <div className='preview-sec'>
                <div className="hero-gradient-ball alltuchtopdown"></div>

                <div className='container-new'>
                    <div className='row'>
                        <div className='col-12'>
                            <Fade top>
                                <div className='sec-header'>
                                    <h2>Account preview</h2>
                                    <p>Look up any  {title}  member account in preview mode. Enter ID or {Currency} address to preview to view a random account.</p>
                                </div>
                            </Fade>
                        </div>
                        <div className='col-12'>
                            <div className='card border-0 preview-card overflow-hidden'>
                                <form>
                                    <div className="form-group">
                                        {/* <label htmlFor="ID"> </label> */}
                                        <div className='Market__wallet'>
                                            <input type="text" className="form-control" id="ID" placeholder={`Enter ID or ${Currency} wallet`} name="ID" onChange={(e) => { setpreviewID(e.target.value) }} />
                                            <button type='button' className='btn preview-btn' onClick={previewUserClickLogin}> Preview </button>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='recent-sec position-relative'>

                <div className="hero-bg-gradient1"></div>
                <div className="hero-bg-gradient2"></div>
                <div className="ripple-shape">
                    <span className="ripple-1"></span>
                    <span className="ripple-2"></span>
                    <span className="ripple-3"></span>
                    <span className="ripple-4"></span>
                    <span className="ripple-5"></span>
                </div>
                <div className='container-new'>
                    <div className='row'>
                        <div className='col-12'>
                            <Fade top>
                                <div className='sec-header'>
                                    <h2>Platform recent activity
                                        <span id="second-app-title"><InfoIcon /></span>
                                        <Tooltip
                                            className='Tooltip_s'
                                            anchorId="second-app-title"
                                            place="top"
                                            variant="info"
                                            content=" Earn USDT real-time global events with links to transactions in blockchain"
                                        />
                                    </h2>
                                </div>
                            </Fade>
                        </div>

                        <div className='col-md-6'>
                            <div className='card border-0 members-card overflow-hidden'>
                                <Fade left>
                                    <div className='member-sec-a'>
                                        <h6 className='card-heading-members' >Members total
                                            <span id='members-tooltip' ><HelpOutlineIcon /></span>
                                            <Tooltip
                                                className='Tooltip_s'
                                                anchorId="members-tooltip"
                                                place="top"
                                                variant="info"
                                                content="Total number of members in Earn USDT and last 24 hours change"
                                            />
                                        </h6>
                                        <h4>{allUsers?.totalUsers} <span className="up-down-activity text-green"> <small> </small><ArrowUpwardIcon /> {allUsers?.usersLast24Hours} </span>      </h4>
                                    </div>
                                </Fade>
                                {/* ======  */}
                                <Fade left>
                                    <div className='member-sec-a border-0 mb-0 pb-0'>
                                        <h6 className='card-heading-members' >Members received, {Currency}
                                            <span id='members-tooltip-received' ><HelpOutlineIcon /></span>
                                            <Tooltip
                                                className='Tooltip_s'
                                                anchorId="members-tooltip-received"
                                                place="top"
                                                variant="info"
                                                content="Total amount of USDT received by all members of Earn USDT and last 24 hours change"
                                            />
                                        </h6>
                                        <h4>{allUsers?.TotalIncome}  <span className="up-down-activity text-green"> <small> </small><ArrowUpwardIcon /> {allUsers?.LastDayIncome} </span>      </h4>
                                    </div>
                                </Fade>

                            </div>

                        </div>


                        <div className='col-md-6'>
                            <div className='card border-0 members-card overflow-hidden'>
                                <Fade right>
                                    <h4 className='card-heading-members' >{title} Contracts</h4>
                                    <div className='card-body-a'>
                                        <div className='r-token'>
                                            <p className='mb-0 token-name'>{cyclixContractAddress}</p>
                                            <div className='fake-btn' onClick={() => { copyToClipboard(cyclixContractAddress, 'Contract address copied!') }}>
                                                <ContentCopyIcon />
                                            </div>
                                            {/* <div className='fake-btn ms-1'>
                                                    <Link to='/'> 
                                                        <ShareIcon/>
                                                    </Link>
                                                </div> */}
                                        </div>
                                    </div>
                                </Fade>
                                <div className='d-flex w-100'>
                                    <Fade left>
                                        <div className='Transactions-mode'>
                                            <h6 className='card-heading-members' >Transactions made</h6>
                                            <h4>{allUsers?.zeroTotalIncome} <span className="up-down-activity text-green"> <small> </small><ArrowUpwardIcon />  {allUsers?.zeroLastDayIncome}  </span>      </h4>
                                        </div>
                                    </Fade>
                                    <Fade right>
                                        <div className='Transactions-mode'>
                                            <h6 className='card-heading-members'>turnover, {Currency} </h6>
                                            <h4>{allUsers?.TotalTurnover}<span className="up-down-activity text-green"> <small> </small><ArrowUpwardIcon />  {allUsers?.LastDayTurnover}  </span>      </h4>
                                        </div>
                                    </Fade>
                                </div>

                            </div>
                        </div>




                        <div className='col-12'>
                            <DashbordTable />
                        </div>

                        <div className='col-12 text-center platform-sec'>
                            <Fade top>
                                <h2 className='fw-bold'>Need help on how to use the platform?</h2>
                                <p>Get qualified support from  {title} experts via online chat</p>
                                <div className='platform-area'>
                                    <Link to="/" className='btn'> Contact support </Link>
                                </div>
                            </Fade>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Landing;
