import React, { useEffect, useState } from 'react';
import { api_url } from '../config/config';
import ReactPaginate from 'react-paginate';
import _fetch from '../config/api';
import { Link } from "react-router-dom";
import Loader from '../config/Loader';
const UserLevel = () => {



    const [getData, setgetData] = useState();
    const [page, setpage] = useState(1);
    const [limit, setlimit] = useState(10);
    const [totalRecords, settotalRecords] = useState(0);
    const [searchBy, setsearchBy] = useState('wallet_address');
    const [search, setsearch] = useState();
    const getApiData = async () => {
        setgetData()
        const formData = {
        }

        var passData = "";
        if (search !== "" && search !== undefined) {
            passData = `&search=${searchBy}&value=${search}`;
        }
        const data = await _fetch(`${api_url}report/x2LevelReport`, 'get', formData, {});
        if (data.status === 'success') {
            setgetData(data.data);
            // console.log('data.users', data.totalRecord);
            // setuserInfo(data.data.data);
            // settotalRecords(data.total)
            // console.log('data.datadzsfsdf', data.total);
        } else {
            // setregisterStatus(false);
        }
    }
    useEffect(() => {
        getApiData()
    }, [page, limit, searchBy, search])



    const handlePageClick = async (event) => {
        const newOffset = (event.selected + 1);
        setpage(newOffset);
    };


    return (
        <>
            <div className='fxied-width-container'>
                <div className='dashboard-data-container'>
                    <div className='partner-page'>
                        <div className='row m-0 '>
                            <div className='col-12 '>
                                <div className='partner-page-header'>
                                    <h1 className='mb-0'>Generation </h1>
                                    {/* <div className='fillters-button' onClick={() => fillters('show-card')}>
                                        <FilterAltIcon />
                                        Filters
                                    </div> */}
                                </div>
                            </div>

                            <div className='col-12 ' >
                                <div className='filters-show-card' id='show-card'>
                                    <div className='card border-0 filter-card' >
                                        <form>
                                            <div className='row m-0'>
                                                {/* <div className='col-md-4'>
                                                    <label for="sel1" class="form-label">Program</label>
                                                    <select class="form-select" id="sel1" name="sellist1" disabled>
                                                        <option>x2</option>
                                                    </select>

                                                    <button type='submit' className='btn'>Apply fillters</button>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label for="sel1" class="form-label">Level</label>
                                                    <select class="form-select" id="sel1" name="sellist1">
                                                        <option>1</option>
                                                        <option>2</option>
                                                        <option>3</option>
                                                    </select>
                                                    <button type='submit' className='btn'>Reset fillters</button>
                                                </div> */}
                                                <div className='col-md-4'>
                                                    <div class="form-group">
                                                        <label for="search" class="form-label">Search wallet address</label>
                                                        <input type="search" id='search' class="form-control" name='search' onChange={(e) => { setsearch(e.target.value) }} placeholder='Enter  Wallet Address' />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            {/* table */}
                            <div className='col-12'>
                                <div className='card border-0 table-card'>
                                    <div class="table-responsiv__main">
                                        <table class="table table__main_2">
                                            <thead>
                                                <tr>
                                                    <th>Level</th>
                                                    <th>Team Required</th>
                                                    <th>Team</th>
                                                    <th>Action</th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {getData ? '' : <Loader LoaderTxt="Please wait..." />}

                                                {
                                                    getData?.map((val, key) => {

                                                        return (
                                                            <tr key={key}>
                                                                <td>
                                                                    <span className='' >{Number(val?.level)}</span>
                                                                </td>

                                                                <td>
                                                                    {`${val?.totalTeam}`}
                                                                </td>
                                                                <td>
                                                                    {`${val?.achivedTeam}`}
                                                                </td>

                                                                <td>
                                                                    <Link to={'/view-level/' + val?.level}>View Level </Link>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}


                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='pagination-area'>
                                        {/* {totalRecords > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                                            breakLabel="..."
                                            nextLabel=">"
                                            onPageChange={handlePageClick}
                                            pageRangeDisplayed={1}
                                            pageCount={totalRecords / limit}
                                            previousLabel="<"
                                        />} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default UserLevel;
