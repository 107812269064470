import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Currency, api_url, inComeTypes, logo, title } from '../config/config';
import CloseIcon from '@mui/icons-material/Close';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import GroupIcon from '@mui/icons-material/Group';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CampaignIcon from '@mui/icons-material/Campaign';
import smartChain from '../Assets/icon/icon-1.svg'
import busdt_img from '../Assets/icon/KSN-icon.png'
import wallet from '../Assets/icon/wallet.svg'
import 'react-responsive-modal/styles.css';
import bnb from '../Assets/icon/bnb.svg'


import { Modal } from 'react-responsive-modal';
import { Button } from 'react-bootstrap';
import { AttachMoney, LogoutSharp, Widgets } from '@mui/icons-material';
import UnknownUser from '../Assets/icon/UnknownUser.webp'
import _fetch from '../config/api';
import toasted from '../config/toast';










const DasboardHeader = (props) => {

    const [walletMoldal, setwalletMoldal] = useState(false);
    const onOpenwalletMoldal = () => setwalletMoldal(true);
    const onClosewalletMoldal = () => setwalletMoldal(false);
    const [open, setOpen] = useState(false);
    const onOpenModal = () => setOpen(true);
    const onCloseModal = () => setOpen(false);
    const navigate = useNavigate();


    const [registerStatus, setregisterStatus] = useState(false);
    const [userInfo, setuserInfo] = useState({
        user_id: '',
    });


    const previewId = localStorage.getItem("previewId");

    const Private__header = () => {
        const aRemove = document.getElementById("main__header_container");

        if (previewId !== "false") {
            aRemove.classList.remove("Header_preview_ID");

        }

        else {
            aRemove.classList.add("Header_preview_ID");

        }
    };
    const [userInfologin, setuserInfologin] = useState({
        active_status: false,
        business: { direct_business: 0, team_business: 0 },
        created_at: "",
        directs: { total: 0, active: 0, inactive: 0 },
        income_info: { direct_income: 0, level_income: 0, pool_income: 0 },
        sponsor_id: "",

        user_id: "",
        user_info: {
            package: { previous_package: 0, total_package: 0, current_package: 0 },
            phone: "",
            sponsor_id: "",
            upgrade_date: null,
            activate_date: "",
            email: ''
        },
        username: " ",
        wallet_address: ""
    });



    const getUserDetails = async () => {
        const formData = {
        }
        const data = await _fetch(`${api_url}user/userInfo`, 'get', formData, {});
        if (data.message == 'You are not logged in! Please log in to get access.') {
            localStorage.clear();
            navigate("/login");
        }
        if (data.status === 'success') {
            setuserInfologin(data.data.data);
            localStorage.setItem("api_called", 'false');
            localStorage.setItem("user", JSON.stringify(data?.data?.data));

            // console.log('data.data', data.data.data.income_info);
        } else {
            localStorage.setItem("api_called", 'false');

            // setregisterStatus(false);
        }
    }


    useEffect(() => {

        Private__header();
        getUserDetails();
    }, []);



    const [previewID, setpreviewID] = useState('')

    const previewUserClickLogin = async () => {
        // e.preventDefault();

        if (previewID.length > 9) {
            var formData = {
                wallet_address: previewID,
            }
        } else {
            var formData = {
                username: previewID,
            }
        }
        const subdata = await _fetch(`${api_url}auth/loginView`, 'LOGINPOST', formData, {});
        // console.log('subdata',subdata);
        if (subdata.status === "success") {
            localStorage.setItem("auth", 'true');
            localStorage.setItem("accessToken", subdata?.token);
            localStorage.setItem("previewId", true);
            // getUserDetails()
            // navigate("/dashboard");
            window.location.reload()
        } else {
            toasted.error(subdata.message);
        }
    }


    // const togglerBarRemove = () => {
    //     const New__remove = document.getElementById("main__header_container");
    //     New__remove.classList.remove("Header_preview_ID");
    // }

    // const showLoginheader = () => {
    //     const New__remove = document.getElementById("main__header_container");
    //     New__remove.classList.add("Header_preview_ID");
    // }






    const copyToClipboard = (address) => {
        var textField = document.createElement('textarea')
        textField.innerText = address;
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        alert(address);

    }


    const headerModal = () => {
        const New__SWX = document.getElementById("main__header_container");


        if (open === true) {
            onCloseModal(false)
            New__SWX.classList.remove("dash_header__bg");


        }
        else {
            onOpenModal(true)
            New__SWX.classList.add("dash_header__bg");

        }

    }

    let Toggle__add__class = (id) => {
        const rbs = document.getElementById(id);
        rbs.classList.toggle("active");
    }

    let removeToggle = (id) => {
        const rbs = document.getElementById(id);
        const toggle_D = document.getElementById("toggle_1");
        rbs.classList.remove("dash_header__bg");
        toggle_D.classList.remove("active");


        if (open === true) {
            onCloseModal(false)
        }
    }


    let middleToggle = () => {
        const middletf = document.body;
        middletf.classList.toggle("i_i");
    }






    return (
        <>
            <div id='accordion'>
                <div className='fxied-width-container' id='main__header_container'>

                    <div className='header-demo-dash preview__id_header'>
                        <div className='container-dash'>
                            <div className='header-left'>
                                <div className='logo-dash'>
                                    <Link to="/">
                                        <img src={logo} alt="logo" width="100%" />
                                    </Link>
                                </div>
                                <div className='form-dash'>
                                    <form action="" className='d-flex align-items-center'>
                                        <label htmlFor="search" className="form-label mb-0">Preview ID</label>
                                        <p className='form-label__number mb-0 ms-2'> 10001</p>
                                        <div className="form-group d-flex align-items-center only__phone_view">
                                            <input type="search" className="form-control" id="search" onChange={(e) => setpreviewID(e.target.value)} placeholder="Enter ID" name="search" />
                                            <button type='button' onClick={previewUserClickLogin} className='btn btn-success'>GO</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className='header-right'>
                                <Link className='btn registration-btn' to="/login"> Login to your account</Link>

                                {previewId ?
                                    <Link className='btn close-btn close-btn_1 close-btn_LG ' onClick={() => { props.disconnectWallet(); navigate('/') }} to="#"> <CloseIcon /> </Link>
                                    :
                                    <>
                                        <Link className='btn close-btn close-btn_1 close-btn_LG' to="/"><CloseIcon /> </Link>
                                        <button className='btn close-btn close-btn_1 close-btn_MD' onClick={middleToggle} type='button'> <span><Widgets /></span> <span> <CloseIcon /></span> </button>
                                    </>
                                }

                                {/* {previewLogin()} */}

                                <div className='Menu__toggle' onClick={() => { Toggle__add__class("toggle"); headerModal() }} >
                                    <div className="toggle" id='toggle'>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* Main Header___ */}

                    <div className='header-demo-dash login__dash_header'>
                        <div className='container-dash'>
                            <div className='header-left'>
                                <div className='logo-dash-login'>
                                    <Link to="/">
                                        <img src={logo} alt={title?.toLocaleLowerCase() + ` Logo`} width="100%" />
                                    </Link>
                                </div>
                            </div>
                            <div className='header-right'>
                                <ul className='nav spit-area '>
                                    <li className='nav-item first-button'>
                                        <a className='nav-link' style={{ cursor: 'pointer' }}>
                                            <img src={smartChain} alt="img-icon" />
                                            <span>  Smart Chain</span>
                                        </a>
                                    </li>

                                    <li className='nav-item'>

                                        <ul className='nav ms-1 last-area' onClick={() => { onOpenwalletMoldal(); removeToggle("main__header_container"); onCloseModal() }} style={{ cursor: "pointer" }}>
                                            <li className='nav-item none-item'>
                                                <a className='nav-link'>
                                                    <img src={busdt_img} alt="img-icon" />
                                                    {props.balance !== null &&
                                                        <span>   {parseFloat(props?.balance?.tokenBalance)?.toFixed(3)}  {Currency}</span>

                                                    }
                                                    {props.balance === null &&
                                                        <span className='animation__divider'></span>
                                                    }
                                                </a>
                                            </li>
                                            <li className='nav-item none-item'>
                                                <a className='nav-link'>
                                                    <img src={bnb} alt="img-icon" />
                                                    <span> {parseFloat(props?.balance?.coinBalance)?.toFixed(3)} BNB</span>

                                                </a>
                                            </li>
                                            <li className='nav-item'>
                                                <a className='nav-link' >
                                                    <img src={wallet} alt="img-icon" />
                                                    <span>{props?.address?.substring(0, 5) + '....' + props?.address?.substring(props?.address?.length - 5, props?.address?.length)}  </span>

                                                </a>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className='nav-item none__only_lg'>
                                        <Button type='button' onClick={() => { props.disconnectWallet(); navigate('/') }} className='btn exit-button'><LogoutSharp /></Button>
                                    </li>

                                </ul>

                                <div className='Menu__toggle' onClick={() => { Toggle__add__class("toggle_1"); headerModal() }} >
                                    <div className="toggle" id='toggle_1'>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>





                    {/* // sidebar  */}

                    <div className='sidebar-demo-dash'>
                        <div className='sidebar-demo-data'>
                            <ul className='nav flex-column' id='accordion'>
                                <li className='nav-item' >
                                    <Link to="/dashboard" className='nav-link'>
                                        <DashboardIcon />
                                        <span>Dashboard</span>
                                    </Link>
                                </li>
                                {/* Dropdown list section  */}
                                <li className='nav-item'>
                                    <a className='nav-link' data-bs-toggle="collapse" href="#Teams">
                                        <Diversity3Icon />
                                        <span>Team</span>
                                        <KeyboardArrowDownIcon className='down-arrow' />
                                    </a>

                                    <div id="Teams" className="collapse drop-down" data-bs-parent="#accordion">
                                        <ul className='nav'>
                                            <li className='nav-item'>
                                                <Link to="/partners" className='nav-link'>
                                                    <GroupIcon />
                                                    <span> Partners</span>
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link to="/level" className='nav-link'>
                                                    <Diversity2Icon />
                                                    <span>Generation</span>
                                                </Link>
                                            </li>

                                            {/* <li className='nav-item'>
                                                <Link to={"/direct-tree/" + userInfologin?.username} className='nav-link'>
                                                    <Diversity2Icon />
                                                    <span>Directs</span>
                                                </Link>
                                            </li> */}
                                            {/* <li className='nav-item'>
                                                <Link to={"/pool-tree/" + userInfologin?.username} className='nav-link'>
                                                    <Diversity2Icon />
                                                    <span>{title} x10</span>
                                                </Link>
                                            </li> */}
                                            <li className='nav-item'>
                                                <Link to={"/tree-x2/" + userInfologin?.username} className='nav-link'>
                                                    <Diversity2Icon />
                                                    <span>Pool x2</span>
                                                </Link>
                                            </li>
                                            <li className='nav-item'>
                                                <Link to={"/tree-x3/" + userInfologin?.username} className='nav-link'>
                                                    <Diversity2Icon />
                                                    <span>Pool x3</span>
                                                </Link>
                                            </li>
                                            {/* <li className='nav-item'>
                                            <Link to="/stats" className='nav-link'>
                                                <QueryStatsIcon />
                                                <span> Stats</span>
                                            </Link>
                                        </li> */}
                                        </ul>
                                    </div>
                                </li>

                                {/* Dropdown list section  */}
                                {/* <li className='nav-item'>
                                <a className='nav-link' data-bs-toggle="collapse" href="#Information">
                                    <ImportContactsIcon />
                                    <span>Information</span>
                                    <KeyboardArrowDownIcon className='down-arrow' />
                                </a>

                                <div id="Information" className="collapse drop-down" data-bs-parent="#accordion">
                                    <ul className='nav'>
                                        <li className='nav-item'>
                                            <Link to="/instruction" className='nav-link'>
                                                <BookmarksIcon />
                                                <span> Instruction</span>
                                            </Link>
                                        </li>
                                        <li className='nav-item'>
                                            <Link to="/telegrambot" className='nav-link'>
                                                <TelegramIcon />
                                                <span> Telegram bots</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li> */}


                                <li className='nav-item'>
                                    <a className='nav-link' data-bs-toggle="collapse" href="#profit">
                                        <AttachMoney />
                                        <span>Profits</span>
                                        <KeyboardArrowDownIcon className='down-arrow' />
                                    </a>

                                    <div id="profit" className="collapse drop-down" data-bs-parent="#accordion">
                                        <ul className='nav'>

                                            {inComeTypes?.map((val, key) => {
                                                return (
                                                    <li className='nav-item' key={key}>
                                                        <Link to={"/income/" + val?.type} className='nav-link' onClick={() => { onCloseModal() }}>
                                                            <AttachMoney />
                                                            <span> {val?.name}</span>
                                                        </Link>
                                                    </li>
                                                );
                                            })}
                                            <li className='nav-item'>
                                                <Link to="/income-ledger" className='nav-link'>
                                                    <AttachMoney />
                                                    <span> Income Ledger</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>


                                <li className='nav-item' >
                                    <Link to="/dashboard" className='nav-link'>
                                        <CampaignIcon />
                                        <span>Promo & PDFs</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>




                <Modal open={walletMoldal} classNames={{
                    modal: "modal_sec__wallet",
                    modalContainer: "modal__container__sec"
                }} onClose={onClosewalletMoldal} center>
                    <div className="card border-0 wallet___card">
                        <div className='card-header mb-3'>
                            <h4 className='text-light'>Wallet</h4>
                        </div>

                        <div className='card-body card-details'>
                            <div className='card__wallet__ae'>
                                <div className='card__wallet__ae__area'>
                                    <img src={wallet} alt="img-icon" />
                                    <span className='Address__e'>{props?.address?.substring(0, 5) + '....' + props?.address?.substring(props?.address?.length - 5, props?.address?.length)}  </span>
                                </div>
                                <button type='button' className='btn copy_button' onClick={() => { copyToClipboard(props?.address) }}> Copy</button>
                            </div>

                            <div className='card__wallet__ae'>
                                <div className='card__wallet__ae__area'>
                                    <img src={smartChain} alt="img-icon" />
                                    <span>  Smart Chain</span>
                                </div>
                            </div>

                            <h6 className='divider_heading'>Current wallet balance</h6>

                            <div className='card__wallet__ae'>
                                <div className='card__wallet__ae__area'>
                                    <img src={busdt_img} alt="img-icon" />
                                    <span>   {parseFloat(props?.balance?.tokenBalance)?.toFixed(3)}  {Currency}</span>
                                </div>
                            </div>

                            <div className='card__wallet__ae'>
                                <div className='card__wallet__ae__area'>
                                    <img src={bnb} alt="img-icon" />
                                    <span> {parseFloat(props?.balance?.coinBalance)?.toFixed(3)} BNB</span>
                                </div>
                            </div>
                            <button className='btn close_button' type='button' onClick={() => { props.disconnectWallet() }}> Disconnect </button>

                        </div>

                        <div className='card-body-new card-body'>
                            <div className='user__UnknownUser'>
                                <img src={UnknownUser} alt="user_UnknownUser" />
                                <div className='ms-3'>
                                    <p>{registerStatus === false ? `Register in ${Currency}` : `ID: ${userInfo?.user_id}`}</p>
                                    <h6>{registerStatus === false ? `Wallet ${props?.address ? (props?.address?.substring(0, 5) + '....' + props?.address?.substring(props?.address?.length - 5, props?.address?.length)) : ' '} is not a member of ${title?.toLowerCase()}. You can use this wallet to register as a new member.` : `${props?.address?.substring(0, 5) + '....' + props?.address?.substring(props?.address?.length - 5, props?.address?.length)} is  a member of ${title?.toLowerCase()} ${Currency}. `} </h6>
                                </div>
                            </div>

                            {registerStatus === false && <div className='btn__user__new'>
                                <Link to="/registration" className='btn active-button' onClick={() => { onClosewalletMoldal() }}> Create account</Link>
                            </div>}

                            {registerStatus === true && <div className='btn__user__new'>
                                <Link to="/login" className='btn active-button' onClick={() => { onClosewalletMoldal() }}> Login to your account</Link>
                            </div>}


                        </div>

                    </div>
                </Modal>


                <Modal open={open} classNames={{
                    modal: "Header__modal Header__modal__inner",
                    overlay: "Heder__overly",
                    modalAnimationIn: "customEnterModalAnimation",
                    modalAnimationOut: "customLeaveModalAnimation",
                }} onClose={onCloseModal} center>
                    {/* <h3> {title}</h3> */}
                    <div className='search__bar_previewID'>
                        <form action="" className='Preview__Idss' >
                            <label htmlFor="search" className="form-label">Preview ID <span> 10001</span></label>
                            <div className="form-group d-flex align-items-center">
                                <input type="search" className="form-control" id="search" onChange={(e) => setpreviewID(e.target.value)} placeholder="Enter ID" name="search" />
                                <button type='button' onClick={previewUserClickLogin} className='btn'>GO</button>
                            </div>
                        </form>

                    </div>
                    <div className='' id='accordio_phone'>
                        <ul className='nav flex-column'>
                            <li className='nav-item' >
                                <Link to="/dashboard" className='nav-link' onClick={() => { onCloseModal(); Toggle__add__class("toggle_1") }} >
                                    <DashboardIcon />
                                    <span>Dashboard</span>
                                </Link>
                            </li>
                            {/* Dropdown list section  */}
                            <li className='nav-item'>
                                <a className='nav-link' data-bs-toggle="collapse" href="#" data-bs-target="#Teams__phone">
                                    <Diversity3Icon />
                                    <span>Team</span>
                                    <KeyboardArrowDownIcon className='down-arrow' />
                                </a>

                                <div id="Teams__phone" className="collapse " data-bs-parent="#accordio_phone">
                                    <ul className='nav'>
                                        <li className='nav-item'>
                                            <Link to="/partners" className='nav-link' onClick={() => { onCloseModal(); Toggle__add__class("toggle_1") }}>
                                                <GroupIcon />
                                                <span> Partners</span>
                                            </Link>
                                        </li>
                                        <li className='nav-item'>
                                            <Link to="/level" className='nav-link' onClick={() => { onCloseModal(); Toggle__add__class("toggle_1") }}>
                                                <Diversity2Icon />
                                                <span>Generation</span>
                                            </Link>
                                        </li>

                                        {/* <li className='nav-item'>
                                            <Link to={"/direct-tree/" + props?.address} className='nav-link' onClick={() => { onCloseModal(); Toggle__add__class("toggle_1") }}>
                                                <Diversity2Icon />
                                                <span>Directs</span>
                                            </Link>
                                        </li> */}
                                        {/* <li className='nav-item'>
                                            <Link to={"/pool-tree/" + props?.address} className='nav-link' onClick={() => { onCloseModal(); Toggle__add__class("toggle_1") }}>
                                                <Diversity2Icon />
                                                <span>{title} x10</span>
                                            </Link>
                                        </li> */}
                                        <li className='nav-item'>
                                            <Link to={"/tree-x2/" + userInfologin?.username} className='nav-link' onClick={() => { onCloseModal(); Toggle__add__class("toggle_1") }}>
                                                <Diversity2Icon />
                                                <span>pool x2</span>
                                            </Link>
                                        </li>
                                        <li className='nav-item'>
                                            <Link to={"/tree-x3/" + userInfologin?.username} className='nav-link' onClick={() => { onCloseModal(); Toggle__add__class("toggle_1") }}>
                                                <Diversity2Icon />
                                                <span>pool x3</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>



                            <li className='nav-item'>
                                <a className='nav-link' data-bs-toggle="collapse" href="#profit__phone">
                                    <AttachMoney />
                                    <span>Profits</span>
                                    <KeyboardArrowDownIcon className='down-arrow' />
                                </a>

                                <div id="profit__phone" className="collapse drop-down" data-bs-parent="#accordio_phone">
                                    <ul className='nav'>
                                        {inComeTypes?.map((val, key) => {
                                            return (
                                                <li className='nav-item' key={key}>
                                                    <Link to={"/income/" + val?.type} className='nav-link' onClick={() => { onCloseModal(); Toggle__add__class("toggle_1") }}>
                                                        <AttachMoney />
                                                        <span> {val?.name}</span>
                                                    </Link>
                                                </li>
                                            );
                                        })}


                                        <li className='nav-item'>
                                            <Link to="/income-ledger" className='nav-link' onClick={() => { onCloseModal(); Toggle__add__class("toggle_1") }}>
                                                <AttachMoney />
                                                <span> Income Ledger</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>




                            <li className='nav-item' >
                                <Link to="/dashboard" className='nav-link' onClick={() => { onCloseModal(); Toggle__add__class("toggle_1") }}>
                                    <CampaignIcon />
                                    <span>Promo & PDFs</span>
                                </Link>
                            </li>

                            <li className='nav-item' >
                                <button type='button' onClick={() => { props.disconnectWallet() }} className='nav-link button-links'>
                                    <LogoutSharp />
                                    <span> Disconnect</span>
                                </button>
                            </li>
                        </ul>
                    </div>
                </Modal>

            </div>


        </>
    );
}

export default DasboardHeader;
