import React, { useEffect, useState } from 'react';
import userActive from "../Assets/Imgeas/activeUser.png"
import userInactive from "../Assets/Imgeas/InactiveUser.png"
import { api_url, title } from '../config/config';
import _fetch from '../config/api';
import { Link, useParams } from 'react-router-dom';

const Tree = () => {

    // const toggleClass = (id) => {
    //     const element = document.getElementById(id);
    //     if (element) {
    //         element.classList.toggle('show__details');
    //     }
    // };
    const { username } = useParams();

    const [data, setdata] = useState({ mainUser: {username: ''}, level1: [], level2: [], level3: [] });

    const getData = async () => {
        var formData = {};
        const subdata = await _fetch(`${api_url}activation/treeX2?username=${username}`, 'GET', formData, {});
        if (subdata.status === "success") {
            localStorage.setItem("api_called", 'false');
            setdata(subdata);
        }
    }
    useEffect(() => {
        getData();
    }, [username])

    return (
        <div className='__tree_v1 fxied-width-container '>
            <div className='dashboard-data-container'>

                <div className='row'>
                    <div className='col-12 '>
                        <div className='partner-page-header'>
                            <h1 className='mb-0'> {title} x2 Tree </h1>
                        </div>
                    </div>

                    <div className='col-12 team__sec'>
                        <div className="card mt-2 main-tree">


                            <div className="card-body">

                                <ul className="tree vertical ">
                                    <li>
                                        <div className="admin">
                                            <div className="admin-img">
                                                <img className="img-sec" src={userActive} alt="active user" />
                                            </div>

                                            <div className="user__name">
                                                <div className="user-img">
                                                    <p> Admin </p>
                                                    <p> ID : #{data?.mainUser?.username} </p>
                                                </div>
                                            </div>
                                        </div>

                                        <ul>
                                            <li>
                                                <div className="user-main-sec">
                                                    <Link to={`/tree-x2/${data?.l11}`} target='_blank' className="stretched-link"></Link>
                                                    <div className="user">
                                                        <div className="user-img">
                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                        </div>
                                                    </div>

                                                    <div className="user__name">
                                                        <div className="user-img">
                                                            <p> ID : #{data?.l11}</p>
                                                        </div>

                                                    </div>

                                                    <div className="cads">
                                                        <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                                        <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                                        <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                                        <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                                    </div>
                                                </div>
                                                <ul>
                                                    <li>
                                                        <div className="user-main-sec">
                                                            <Link to={`/tree-x2/${data?.l21}`} target='_blank' className="stretched-link"></Link>
                                                            <div className="user">
                                                                <div className="user-img">
                                                                    <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                </div>
                                                            </div>

                                                            <div className="user__name">
                                                                <div className="user-img">
                                                                    {/* <p>  ( Usddder ) </p> */}
                                                                    <p> ID : #{data?.l21}</p>
                                                                </div>
                                                            </div>

                                                            <div className="cads">
                                                                <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                                                <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                                                <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                                                <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                                            </div>
                                                        </div>
                                                        <ul>
                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l31}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l31}</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="cads">
                                                                        <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                                                        <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                                                        <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                                                        <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l32}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l32}</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="cads">
                                                                        <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                                                        <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                                                        <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                                                        <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                        </ul>
                                                    </li>

                                                    <li>
                                                        <div className="user-main-sec">
                                                            <Link to={`/tree-x2/${data?.l22}`} target='_blank' className="stretched-link"></Link>
                                                            <div className="user">
                                                                <div className="user-img">
                                                                    <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                </div>
                                                            </div>

                                                            <div className="user__name">
                                                                <div className="user-img">
                                                                    <p> ID : #{data?.l22}</p>
                                                                </div>
                                                            </div>

                                                            <div className="cads">
                                                                <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                                                <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                                                <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                                                <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                                            </div>
                                                        </div>

                                                        <ul>
                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l33}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l33}</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="cads">
                                                                        <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                                                        <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                                                        <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                                                        <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l34}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l34}</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="cads">
                                                                        <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                                                        <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                                                        <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                                                        <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>

                                            <li>
                                                <div className="user-main-sec">
                                                    <Link to={`/tree-x2/${data?.l12}`} target='_blank' className="stretched-link"></Link>
                                                    <div className="user">
                                                        <div className="user-img">
                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                        </div>
                                                    </div>

                                                    <div className="user__name">
                                                        <div className="user-img">
                                                            <p> ID : #{data?.l12} </p>
                                                        </div>
                                                    </div>

                                                    <div className="cads">
                                                        <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                                        <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                                        <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                                        <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                                    </div>
                                                </div>

                                                <ul>
                                                    <li>
                                                        <div className="user-main-sec">
                                                            <Link to={`/tree-x2/${data?.l23}`} target='_blank' className="stretched-link"></Link>
                                                            <div className="user">
                                                                <div className="user-img">
                                                                    <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                </div>
                                                            </div>

                                                            <div className="user__name">
                                                                <div className="user-img">
                                                                    <p> ID : #{data?.l23} </p>
                                                                </div>
                                                            </div>

                                                            <div className="cads">
                                                                <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                                                <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                                                <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                                                <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                                            </div>
                                                        </div>

                                                        <ul>
                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l35}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l35} </p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="cads">
                                                                        <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                                                        <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                                                        <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                                                        <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l36}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l36} </p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="cads">
                                                                        <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                                                        <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                                                        <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                                                        <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </li>

                                                    <li>
                                                        <div className="user-main-sec">
                                                            <Link to={`/tree-x2/${data?.l24}`} target='_blank' className="stretched-link"></Link>
                                                            <div className="user">
                                                                <div className="user-img">
                                                                    <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                </div>
                                                            </div>

                                                            <div className="user__name">
                                                                <div className="user-img">
                                                                    <p> ID : #{data?.l24}</p>
                                                                </div>
                                                            </div>

                                                            <div className="cads">
                                                                <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                                                <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                                                <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                                                <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                                            </div>
                                                        </div>

                                                        <ul>
                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l37}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l37} </p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="cads">
                                                                        <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                                                        <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                                                        <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                                                        <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                                                    </div>
                                                                </div>
                                                            </li>

                                                            <li>
                                                                <div className="user-main-sec">
                                                                    <Link to={`/tree-x2/${data?.l38}`} target='_blank' className="stretched-link"></Link>
                                                                    <div className="user">
                                                                        <div className="user-img">
                                                                            <img className="img-sec" src={userInactive} alt="userInactive" />
                                                                        </div>
                                                                    </div>

                                                                    <div className="user__name">
                                                                        <div className="user-img">
                                                                            <p> ID : #{data?.l38} </p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="cads">
                                                                        <p> <span>User ID: </span>   <span> AD867884 </span> </p>
                                                                        <p>   <span>User Name :</span> <span> kuldeep</span> </p>
                                                                        <p>  <span>Package Amount :</span> <span> 5000</span> </p>
                                                                        <p>  <span>Total Business :</span> <span> 63280 </span> </p>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>


    );
}

export default Tree;
