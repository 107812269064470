import { AccountBalanceWallet, OpenInNew, PersonAddAlt, RemoveRedEye } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Currency, api_url, blockChain_url, CurrencySec, calcuTime } from '../config/config'
import _fetch from '../config/api'
import dateFormat from "dateformat";

import FlareRoundedIcon from '@mui/icons-material/FlareRounded';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { CopyAll, PersonAddAltRounded, SettingsSharp } from '@mui/icons-material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Loader from '../config/Loader';
const DashbordTable = () => {

    const [getTransactoin, setgetTransactoin] = useState([]);
    const [siteDetails, setsiteDetails] = useState();
    const [limit, setlimit] = useState(10);
    const [page, setpage] = useState(1);

    const getTransaction = async () => {
        const formData = {
        }
        const data = await _fetch(`${api_url}activation/recentActivity?page=${page}`, 'get', formData, {});
        // if (data.status === 'success') {
        setgetTransactoin(data?.data);
        // } else {
        //     // setregisterStatus(false);
        // }
    }


    useEffect(() => {
        getTransaction();
    }, [ page]);


    return (
        <>
            <div className='dashboard-recent-sec'>

                {/* {getTransactoin == "" && */}

                <div className='card border-0 activity-card'>
                    <div className='table-responsiv__main'>
                        <table className="table table__main">
                            <tbody>
                                {getTransactoin ? '' : <Loader LoaderTxt="Please wait..." />}

                                {getTransactoin?.map((val, key) => {
                                    return (
                                        <tr key={key}>
                                            <td>
                                                <div className='id_profile'>
                                                    {val?.description !== 'new user joined' && val?.description !== 'new upgrade user' ?

                                                        <div className='id_icon id_wallet'>
                                                            <AccountBalanceWalletIcon />
                                                        </div>

                                                        :
                                                        <div className='id_icon addUser'>
                                                            <PersonAddAltRounded />
                                                        </div>
                                                    }

                                                    <div className='reffral_txt'>
                                                        <span className='__txt'>{val?.description == 'new user joined' || val?.description == 'new upgrade user' ? <span className='description_tt'> {val?.description} </span> : ''}</span>
                                                        <Link className='btn'> {val?.username == '0x0000000000000000000000000000000000000000' ? 'Burning' : 'ID ' + val?.username}</Link>
                                                    </div>

                                                    {val?.description !== 'new user joined' && val?.description !== 'new upgrade user' && <p className='blanace-profit' > +{val?.amount?.toFixed(2)} {CurrencySec}  {val?.description !== 'new user joined' ? val?.type !== 'auto_pool_energy' ? <p className='__xvalue'> in <span>x2</span> </p> : <p className='__xvalue'> <span>x3</span> </p> : ''}</p>}
                                                </div>
                                            </td>
                                            {/* <td>{val?.type?.replace('_', ' ')?.toUpperCase()}</td> */}
                                            {/* <td >
                                                                    <p className='New__overflow__id'><span>{val?.description}</span></p>
                                                                </td> */}

                                            {/* <td> {val?.description?.substring(0, 30) + '....' + val?.description?.substring(val?.description?.length - 0, val?.description?.length)}</td> */}
                                            <td className='time text-center'>
                                                <div className='time-a'>
                                                    <a href={blockChain_url + val?.hash} target='_blank'>    <OpenInNewIcon /> </a>
                                                    <p>{calcuTime(val.created_at)}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>

                    <div className='btn-table'>
                        <div onClick={() => setpage(page + 1)} className='btn'> <RemoveRedEyeIcon /> See More activity </div>
                    </div>
                </div>
                {/* // } */}


            </div>
        </>
    )
}

export default DashbordTable