import React from 'react'
import bnb from '../Assets/icon/bnb.svg'
import { AccountBalanceWalletRounded, CachedRounded, KeyboardArrowLeftRounded, KeyboardArrowRightRounded, PeopleOutlineRounded } from '@mui/icons-material';
import { Currency } from '../config/config';




const Directory = () => {
    return (
        <>

            <div className='fxied-width-container'>
                <div className='dashboard-data-container'>
                    <div className='partner-page profile_page'>
                        <div className='row m-0 '>
                            <div className='col-12 '>
                                <div className='partner-page-header'>
                                    <p> <span> Dashboard /</span> Directory </p>
                                    <h1 className='mb-0'>Directory Level 1</h1>
                                </div>
                            </div>

                            <div className='col-12'>

                                <div className="directory__sec">
                                    <div className='prev__button'>
                                        <button type='button' className='button__sec' > <KeyboardArrowLeftRounded /></button>
                                        <button type='button' className='button__sec next-button-block-md'>   <KeyboardArrowRightRounded /> </button>

                                    </div>


                                    <div className='card directory'>
                                        <div className='card-header'>
                                            <h3>Lvl 1</h3>
                                            <h1> ID 1</h1>
                                            <div className='coin__sec'>
                                                <img src={bnb} alt='coin__img' />
                                                5
                                            </div>
                                        </div>

                                        <div className='card-body'>
                                            <div className='card-box'>
                                                <div className='id__box'>
                                                    <p>100000</p>
                                                </div>

                                                <div className='id__box'>
                                                    <p>100011</p>
                                                </div>
                                                <div className='id__box'>
                                                    <p>100011</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='card-footer'>
                                            <div className='card-footer-left'>
                                                <div className='card-user-details'>
                                                    <p>Partners</p>
                                                    <div className='partners_number'> <PeopleOutlineRounded />  20835 </div>
                                                </div>

                                                <div className='card-user-details ms-md-5'>
                                                    <p>Cycles</p>
                                                    <div className='partners_number'> <CachedRounded />  8243 </div>
                                                </div>

                                            </div>

                                            <div className='card-footer-right'>
                                                <div className='card-user-details'>
                                                    <p>Total level revenue</p>
                                                    <div className='partners_number'> <AccountBalanceWalletRounded />  1239821 {Currency} </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='prev__button next-button-none-md'>
                                        <button type='button' className='button__sec'>   <KeyboardArrowRightRounded /> </button>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Directory