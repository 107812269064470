import React from 'react';
import LoaderGIF from "../Assets/Imgeas/LoaderGIf.gif"


const ButtonLoader = (props) => {
    return (
        <div className='__buttonLoader'>
             <div className='Loader_'>
                <img src={LoaderGIF} alt='loader'/>
                {props.LoaderTxt}
               </div>

        </div>
    );
}

export default ButtonLoader;
