import React from "react";
import { title } from "../config/config";
import TelegramIcon from '@mui/icons-material/Telegram';
import { Link } from "react-router-dom";




const TelegramBot = () => {
  return (
    <>
      <div className="fxied-width-container">
        <div className="dashboard-data-container">
          <div className="bot-page">
            <div className='row m-0 '>
                <div className='col-12 '>
                    <div className='bot-page-header'>
                        <h1 className='mb-0'>Telegram bots <small><span>ID 20933</span></small></h1>
                    </div>
                </div>
                <div className="col-12 mt-5">
                    <div className="card border-0 bot-card">
                        <div className="bot-bot">
                            <div className="bot-icon">
                                <TelegramIcon/>
                            </div>
                            <div className="bot-name">
                                <h4 className="mb-0" >{title} BSC Notifier Bot</h4>
                                <h6 className="mb-0" >New partners and transactions notifications</h6>
                            </div>
                            <Link className="stretched-link" to="#" target="_blank"></Link>
                        </div>
                        <div className="bot-bot">
                            <div className="bot-icon">
                                <TelegramIcon/>
                            </div>
                            <div className="bot-name">
                                <h4 className="mb-0">{title} BSC Notifier Bot</h4>
                                <h6 className="mb-0">New partners and transactions notifications</h6>
                            </div>
                            <Link className="stretched-link" to="#" target="_blank"></Link>
                        </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TelegramBot;
