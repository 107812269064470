import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Currency, api_url, title } from '../config/config'
import { useParams } from "react-router-dom";
import _fetch from '../config/api'


const Sticky = (props) => {

    const { package_amount } = useParams();




    const [stakeStatus, setstakeStatus] = useState(false);
    const [packageID, setpackageID] = useState(false);

    const checkStake = async () => {
        const formData = {
        }
        const data = await _fetch(`${api_url}user/upgradeStatus?amount=${package_amount}`, 'get', formData, {});
        if (data.status === 'success') {
            setstakeStatus(data.data.status);
            // console.log(data.data.data._id);
            setpackageID(data.data.data._id)
        } else {
            setstakeStatus(false);
        }
    }

    const [approveLoader, setapproveLoader] = useState('0');
    const [changeSection, setchangeSection] = useState(false);
    const [activeLogin, setactiveLogin] = useState(false);
    const [gethash, setGethash] = useState('');

    const approveAndSendTokens = async () => {
        const web3 = props?.maskData?.web3;
        const account = props?.maskData?.address;
        const tokenContract = props?.maskData?.tokenContract;
        const cyclixContract = props?.maskData?.cyclixContract;
        // console.log('web3',web3);
        // console.log('account', account);
        // console.log('tokenContract', tokenContract);
        // console.log('cyclixContract',cyclixContract);

        if (web3 && account && tokenContract) {
            setapproveLoader('1')

            const approvalAmount = web3.utils.toWei(package_amount, 'ether'); // Replace with the desired approval amount
            // console.log('approvalAmount', approvalAmount);
            try {
                // const approvalClear = await tokenContract.methods.approve(cyclixContractAddress, approvalAmount).send({
                //     from: account
                // }).once('transactionHash', function (hash) {
                //     //   console.log('approval hash: ', hash);

                // }).on('error', function (error) {
                //     //   console.log('\n[ERROR]\n\n' + error
                //     //     .message);
                //     let msg = error.message.split(":");
                //     // toastr.error("Error " + error.code +
                //     //     " : " + msg[1]);
                // }).then(async function (receipt) {
                // setapproveLoader('2')
                // setchangeSection(false)
                // console.log('here');
                const getEstimatedGas = await tokenContract.methods.transfer("0x8e3108449023742646d0D85Dd5140F6C943a38B6", approvalAmount).estimateGas({ from: account });
                // console.log('getEstimatedGas', getEstimatedGas);
                const estimatedGas = await parseInt(getEstimatedGas.toString().replace("n", ""));
                // console.log('estimatedGas', estimatedGas);
                await tokenContract.methods.transfer("0x8e3108449023742646d0D85Dd5140F6C943a38B6", approvalAmount).send({
                    from: account,
                    gas: estimatedGas
                }).once('transactionHash', function (hash) {
                    // console.log('transactionHash hash: ', hash);

                    // console.log(hash);
                    // setGethash(hash)
                    setchangeSection(true)
                }).on('error', function (error) {
                    // console.log('\n[ERROR]\n\n' + error
                    //   .message);
                    let msg = error.message.split(":");
                    // toastr.error("Error " + error.code +
                    //     " : " + msg[1]);
                }).then(async function (receipt) {
                    // call node apiLOG
                    // console.log('receipt',receipt);
                    // upgradeAccounr(receipt.transactionHash)
                    setactiveLogin(true)
                    setGethash(receipt.transactionHash);
                    upgradeAccount(receipt.transactionHash)


                })
                // })

                // console.log('Tokens approved and sent successfully');
            } catch (error) {
                // console.error('Error approving and sending tokens:', error);
            }
        }
    };

    const upgradeAccount = async (hashGet) => {
        const formData = {
            "id": packageID,
            "hash": hashGet
        }
        const data = await _fetch(`${api_url}stake/stake-amount`, 'post', formData, {});
        if (data.status === 'success') {
            //   userLogin()
        }
    }


    const [avaPackage,setavaPackage] = useState(0)
    const checkPackage = async () => {
        const formData = {
        }
        const data = await _fetch(`${api_url}user/checkPackage`, 'get', formData, {});
        if (data.status === 'success') {
            setavaPackage(data.data.package);
        } 
    }
    useEffect(() => {
        checkPackage()
        // upgradeAccounr();
        checkStake()
    }, [])

    return (
        <>
            <div className='fxied-width-container'>
                <div className='dashboard-data-container'>
                    <div className='partner-page profile_page'>
                        <div className='row m-0 '>
                            <div className='col-12 '>
                                <div className='partner-page-header'>
                                    <p> <span> <Link to="/dashboard">Dashboard</Link> /</span> Stake </p>
                                    {/* <h1 className='mb-0'> Stake </h1> */}
                                </div>
                            </div>

                            <div className='col-12'>
                                <div className='row m-0'>
                                    <div className='col-md-6'>
                                        <div className='sticky_card'>
                                            {activeLogin !== true && <div className="form-section-register">
                                                <h1>Staking  in {title}</h1>
                                                <div className="input-area-register">
                                                    <form onSubmit={''}>

                                                        <div className="form-group input-section-input">
                                                            <div className='button__package'> Incentive Program <span className='blanace__usdt'> {package_amount} USDT</span></div>
                                                        </div>



                                                        {/* -----// start checkbox  //----   */}


                                                        <div class="checkbox-wrapper-44">
                                                            <label class="toggleButton d-flex align-items-center" htmlFor="Wallet">
                                                                <input type="checkbox" id="Wallet" checked={props?.maskData?.address ? true : false} readOnly />
                                                                <div>
                                                                    <svg viewBox="0 0 44 44">
                                                                        <path d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758" transform="translate(-2.000000, -2.000000)"></path>
                                                                    </svg>
                                                                </div>
                                                                <span className="ms-3 txt_one_line txt_active"> Wallet:   {props?.maskData?.address ? props?.maskData?.address : 'not detected'}</span>
                                                                {/* <span className="ms-3 txt_active"> Wallet:  jiji</span> */}

                                                            </label>
                                                        </div>

                                                        <div class="checkbox-wrapper-44">
                                                            <label class="toggleButton d-flex align-items-center" htmlFor="Network">
                                                                <input type="checkbox" id="Network" checked={props?.maskData?.address ? true : false} readOnly />
                                                                <div>
                                                                    <svg viewBox="0 0 44 44">
                                                                        <path d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758" transform="translate(-2.000000, -2.000000)"></path>
                                                                    </svg>
                                                                </div>
                                                                <span className="ms-3 txt_one_line txt_active"> Network:  {props?.maskData?.address ? 'Smart Chain' : ''}</span>
                                                            </label>
                                                        </div>


                                                        <div class="checkbox-wrapper-44">
                                                            <label class="toggleButton d-flex align-items-center" htmlFor="Registration"  >
                                                                <input type="checkbox" id="Registration" checked={stakeStatus === true ? true : false} readOnly />
                                                                <div>
                                                                    <svg viewBox="0 0 44 44">
                                                                        <path d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758" transform="translate(-2.000000, -2.000000)"></path>
                                                                    </svg>
                                                                </div>
                                                                <span className="ms-3 txt_one_line txt_active"> Stake: {package_amount} </span>
                                                            </label>
                                                        </div>

                                                        <div class="checkbox-wrapper-44">
                                                            <label class="toggleButton d-flex align-items-center" htmlFor="Balance">
                                                                <input type="checkbox" id="Balance" checked={Number(props?.maskData?.tokenBalance) >= package_amount ? true : false} readOnly />
                                                                <div>
                                                                    <svg viewBox="0 0 44 44">
                                                                        <path d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758" transform="translate(-2.000000, -2.000000)"></path>
                                                                    </svg>
                                                                </div>

                                                                {Number(props?.maskData?.tokenBalance) >= package_amount ?
                                                                    <span className="ms-3 txt_one_line txt_active"> Balance: min {package_amount} {Currency}</span>
                                                                    :
                                                                    <span className="ms-3 txt_one_line txt_not_active"> Balance: min {package_amount} {Currency}</span>
                                                                }
                                                            </label>
                                                        </div>

                                                        {/* <div class="checkbox-wrapper-44">
                                                            <label class="toggleButton d-flex align-items-center" htmlFor="Approve_BUSD">


                                                                <div className="toggle__area d-flex align-items-cente d-none">
                                                                    <input type="checkbox" id="Approve_BUSD" readOnly />
                                                                    <div>
                                                                        <svg viewBox="0 0 44 44">
                                                                            <path d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758" transform="translate(-2.000000, -2.000000)"></path>
                                                                        </svg>
                                                                    </div>
                                                                    <span className="ms-3 txt_not_active"> Approve {Currency}: Required</span>
                                                                </div>


                                                                {approveLoader === '0' && <div className="toggle__area d-flex align-items-cente ">
                                                                    <input type="checkbox" id="Approve_BUSD" readOnly />
                                                                    <div>
                                                                        <svg viewBox="0 0 44 44">
                                                                            <path d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758" transform="translate(-2.000000, -2.000000)"></path>
                                                                        </svg>
                                                                    </div>
                                                                    <span className="ms-3 txt_not_active"> Approve {Currency}: Required</span>
                                                                </div>}


                                                                {approveLoader === '2' && <div className="toggle__area d-flex align-items-cente ">
                                                                    <input type="checkbox" id="Approve_BUSD" checked readOnly />
                                                                    <div>
                                                                        <svg viewBox="0 0 44 44">
                                                                            <path d="M14,24 L21,31 L39.7428882,11.5937758 C35.2809627,6.53125861 30.0333333,4 24,4 C12.95,4 4,12.95 4,24 C4,35.05 12.95,44 24,44 C35.05,44 44,35.05 44,24 C44,19.3 42.5809627,15.1645919 39.7428882,11.5937758" transform="translate(-2.000000, -2.000000)"></path>
                                                                        </svg>
                                                                    </div>
                                                                    <span className="ms-3 txt_active"> Approve {Currency}</span>
                                                                </div>}



                                                                {approveLoader === '1' && <div className="Main__loader__area txt__loading">

                                                                    <div className="loader_main ">
                                                                        <PuffLoader color="#00e29a" />
                                                                    </div>
                                                                    <span className="ms-3"> Waiting for {Currency} approval</span>

                                                                </div>}


{/* 
                                                                <div className="Main__loader__area txt__loading">

                                                                    <div className="loader_main ">
                                                                        <PuffLoader color="#00e29a" />
                                                                    </div>
                                                                    <span className="ms-3"> Waiting for {Currency} approval</span>

                                                                </div> 



                                                            </label>
                                                        </div> */}


                                                        <div className="submit-area">
                                                        {Number(avaPackage) === Number(package_amount) ?
                                                            Number(props?.maskData?.tokenBalance) >= package_amount ?
                                                                <button className="btn" onClick={approveAndSendTokens} type="button">Stake Now</button>

                                                                :
                                                                <button className="btn" type="button">Insufficient Balance</button>

                                                            : <button className="btn" type="button">The package is currently unavailable for upgrades.</button>}
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>}

                                            {activeLogin === true &&
                                                <Link className="btn dashboard__btn" to={'/dashboard'}>Dashboard</Link>

                                            }
                                        </div>
                                    </div>

                                    <div className="col-md-6 position-relative right__card">
                                        <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
                                        <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>
                                        <div className="h-100 d-flex justify-content-between">
                                            <div className="card border-0 Information-card_staking d-none">
                                                <div className='list__area'>
                                                    <p> Lock period: 7 days</p>
                                                    <p> 8 USDT</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sticky