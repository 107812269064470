import React from 'react';
import { ColorRing, InfinitySpin, ProgressBar } from 'react-loader-spinner';


const Loader = (props) => {
    return (
        <tr className='__loader__s'>
            <td colSpan={9}>
               <div className='Loader_'>
               <ColorRing
                    visible={true}
                    height="50"
                    width="75"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                />
                {props.LoaderTxt}
               </div>

            </td>

        </tr>
    );
}

export default Loader;
