import React from 'react'
import { logo } from '../config/config'
import { Link } from 'react-router-dom'

const HomeFooter = () => {
    return (
        <>
            <div className='Home__footer'>
                <div className='Footer___box'>
                    <div className='Footer___inner'>
                        <div className=''>
                            <Link to="/" target='_black'> 
                                <img src={logo} alt="logo" className='logo__img'/>
                            </Link>
                        </div>
                        <p className='mb-0'>Copyright &copy; {(new Date().getFullYear())} All rights reserved </p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HomeFooter