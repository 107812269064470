import React from "react";
import HelpIcon from '@mui/icons-material/Help';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import CopyAllIcon from '@mui/icons-material/CopyAll';

const Links = () => {
  return (
    <>
      <div className="fxied-width-container links">
        <div className="dashboard-data-container">
          <div className='row m-0 '>
                <div className='col-12 '>
                    <div className='bot-page-header mt-4'>
                        <h1 className='mb-0'>Personal link  <small><span>ID 20933</span></small></h1>
                    </div>
                </div>
                <div className="col-12 Links-page" >
                  <div className="card border-0 Personal-card">
                      <div className="Personal-left-sec">
                          <h5>Personal link  <span id="Personal_link_a" ><HelpIcon/></span>
                          <Tooltip
                                  className='Tooltip_s Tooltip_sb'
                                  anchorId="Personal_link_a"
                                  place="bottom"
                                  variant="info"
                                  // content="Invite your friends to Earn BUSD and build your team by sharing your link"
                                  />
                          </h5>
                          {/* <h2>  </h2> */}
                      </div>
                      <div className="Personal-right-sec">
                          <button type="button" className="btn">Copy <CopyAllIcon/></button>
                      </div>
                  </div>
                </div>
            </div>
        </div>
      </div>


      
    </>
  );
};

export default Links;
