import React from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import LaunchIcon from '@mui/icons-material/Launch';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import { Currency } from '../config/config';
import { Link } from 'react-router-dom';

const Stats = () => {

    function fillters(id) {
        var element = document.getElementById(id);
        element.classList.toggle("show-full-card");
      }


  return (
    <>
        <div className='fxied-width-container'>
            <div className='dashboard-data-container'>
               <div className='Stats-page'>
                    <div className='row m-0 '>
                        <div className='col-12 '>
                            <div className='Stats-page-header'>
                                <h1 className='mb-0'>Stats <small><span>ID 20933</span></small></h1>
                                <div className='fillters-button' onClick={() => fillters('show-card')}>
                                        <FilterAltIcon/>
                                        Filters
                                </div>
                            </div>
                        </div>

                        <div className='col-12 ' >
                            <div className='filters-show-card' id='show-card'>
                                <div className='card border-0 filter-card' >
                                    <form>
                                        <div className='row m-0'>
                                            <div className='col-md-4'>
                                                <label for="sel1" class="form-label">Program</label>
                                                <select class="form-select" id="sel1" name="sellist1" disabled>
                                                <option>x2</option>
                                                </select>

                                                <button type='submit' className='btn'>Apply fillters</button>
                                            </div>
                                            <div className='col-md-4'>
                                                <label for="sel1" class="form-label">Level</label>
                                                <select class="form-select" id="sel1" name="sellist1">
                                                <option>1</option>
                                                <option>2</option>
                                                <option>3</option>
                                                </select>
                                                <button type='submit' className='btn'>Reset fillters</button>
                                            </div>
                                            <div className='col-md-4'>
                                                <div class="form-group">
                                                    <label for="search" class="form-label">Search ID / address</label>
                                                    <input type="search" id='search' class="form-control" name='search' placeholder='Enter ID / Wallet'/>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>

                        {/* table */}
                        <div className='col-12'>
                            <div className='card border-0 table-card'>
                                <div class="table-responsive-xl">
                                    <table class="table">
                                        <thead>
                                        <tr>
                                        <th>Type</th>
                                            <th>Date</th>
                                            <th>ID</th>
                                            <th>Program</th>
                                            <th>Level</th>
                                            <th className='text-center'>Wallet</th>
                                            <th className='text-end'>{Currency} profit / Type</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td> <span><WbSunnyIcon/></span> </td>
                                                <td>  <span className='date-time' >02.10.2022 15:43</span> </td>
                                                <td> <Link to='#' className='btn'>ID 743814</Link> </td>
                                                <td className='text-center'  > 2x </td>
                                                <td className='text-center' > 2 </td>
                                                <td>
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        <div className='user_ID'><span>0x34fc137de405a2793147c54c2ace16632a9316da</span></div>
                                                            <div className='btns-al'>
                                                                <span><CopyAllIcon/></span>
                                                                <span><LaunchIcon/></span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='text-end' >missed profit (level freeze) </td>
                                            </tr>

                                            <tr>
                                                <td> <span><WbSunnyIcon/></span> </td>
                                                <td>  <span className='date-time' >02.10.2022 15:43</span> </td>
                                                <td> <Link to='#' className='btn'>ID 743814</Link> </td>
                                                <td className='text-center'  > 2x </td>
                                                <td className='text-center' > 2 </td>
                                                <td>
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        <div className='user_ID'><span>0x34fc137de405a2793147c54c2ace16632a9316da</span></div>
                                                            <div className='btns-al'>
                                                                <span><CopyAllIcon/></span>
                                                                <span><LaunchIcon/></span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='text-end' >missed profit (level freeze) </td>
                                            </tr>
                                            <tr>
                                                <td> <span><WbSunnyIcon/></span> </td>
                                                <td>  <span className='date-time' >02.10.2022 15:43</span> </td>
                                                <td> <Link to='#' className='btn'>ID 743814</Link> </td>
                                                <td className='text-center'  > 2x </td>
                                                <td className='text-center' > 2 </td>
                                                <td>
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        <div className='user_ID'><span>0x34fc137de405a2793147c54c2ace16632a9316da</span></div>
                                                            <div className='btns-al'>
                                                                <span><CopyAllIcon/></span>
                                                                <span><LaunchIcon/></span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='text-end' >missed profit (level freeze) </td>
                                            </tr>
                                            <tr>
                                                <td> <span><WbSunnyIcon/></span> </td>
                                                <td>  <span className='date-time' >02.10.2022 15:43</span> </td>
                                                <td> <Link to='#' className='btn'>ID 743814</Link> </td>
                                                <td className='text-center'  > 2x </td>
                                                <td className='text-center' > 2 </td>
                                                <td>
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        <div className='user_ID'><span>0x34fc137de405a2793147c54c2ace16632a9316da</span></div>
                                                            <div className='btns-al'>
                                                                <span><CopyAllIcon/></span>
                                                                <span><LaunchIcon/></span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='text-end' >missed profit (level freeze) </td>
                                            </tr>
                                            <tr>
                                                <td> <span><WbSunnyIcon/></span> </td>
                                                <td>  <span className='date-time' >02.10.2022 15:43</span> </td>
                                                <td> <Link to='#' className='btn'>ID 743814</Link> </td>
                                                <td className='text-center'  > 2x </td>
                                                <td className='text-center' > 2 </td>
                                                <td>
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        <div className='user_ID'><span>0x34fc137de405a2793147c54c2ace16632a9316da</span></div>
                                                            <div className='btns-al'>
                                                                <span><CopyAllIcon/></span>
                                                                <span><LaunchIcon/></span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='text-end' >missed profit (level freeze) </td>
                                            </tr>   <tr>
                                                <td> <span><WbSunnyIcon/></span> </td>
                                                <td>  <span className='date-time' >02.10.2022 15:43</span> </td>
                                                <td> <Link to='#' className='btn'>ID 743814</Link> </td>
                                                <td className='text-center'  > 2x </td>
                                                <td className='text-center' > 2 </td>
                                                <td>
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        <div className='user_ID'><span>0x34fc137de405a2793147c54c2ace16632a9316da</span></div>
                                                            <div className='btns-al'>
                                                                <span><CopyAllIcon/></span>
                                                                <span><LaunchIcon/></span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='text-end' >missed profit (level freeze) </td>
                                            </tr>
                                            <tr>
                                                <td> <span><WbSunnyIcon/></span> </td>
                                                <td>  <span className='date-time' >02.10.2022 15:43</span> </td>
                                                <td> <Link to='#' className='btn'>ID 743814</Link> </td>
                                                <td className='text-center'  > 2x </td>
                                                <td className='text-center' > 2 </td>
                                                <td>
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        <div className='user_ID'><span>0x34fc137de405a2793147c54c2ace16632a9316da</span></div>
                                                            <div className='btns-al'>
                                                                <span><CopyAllIcon/></span>
                                                                <span><LaunchIcon/></span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='text-end' >missed profit (level freeze) </td>
                                            </tr>
                                            <tr>
                                                <td> <span><WbSunnyIcon/></span> </td>
                                                <td>  <span className='date-time' >02.10.2022 15:43</span> </td>
                                                <td> <Link to='#' className='btn'>ID 743814</Link> </td>
                                                <td className='text-center'  > 2x </td>
                                                <td className='text-center' > 2 </td>
                                                <td>
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        <div className='user_ID'><span>0x34fc137de405a2793147c54c2ace16632a9316da</span></div>
                                                            <div className='btns-al'>
                                                                <span><CopyAllIcon/></span>
                                                                <span><LaunchIcon/></span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='text-end' >missed profit (level freeze) </td>
                                            </tr>
                                            <tr>
                                                <td> <span><WbSunnyIcon/></span> </td>
                                                <td>  <span className='date-time' >02.10.2022 15:43</span> </td>
                                                <td> <Link to='#' className='btn'>ID 743814</Link> </td>
                                                <td className='text-center'  > 2x </td>
                                                <td className='text-center' > 2 </td>
                                                <td>
                                                    <div className='d-flex justify-content-center align-items-center'>
                                                        <div className='user_ID'><span>0x34fc137de405a2793147c54c2ace16632a9316da</span></div>
                                                            <div className='btns-al'>
                                                                <span><CopyAllIcon/></span>
                                                                <span><LaunchIcon/></span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className='text-end' >missed profit (level freeze) </td>
                                            </tr>
                                         
                                        </tbody>
                                    </table>
                                </div>
                                <div className='pagination-area'>
                                    <ul class="pagination justify-content-center">
                                        <li class="page-item"><Link class="page-link" to="#">Previous</Link></li>
                                        <li class="page-item active"><Link class="page-link" to="#">1</Link></li>
                                        <li class="page-item "><Link class="page-link" to="#">2</Link></li>
                                        <li class="page-item"><Link class="page-link" to="#">3</Link></li>
                                        <li class="page-item"><Link class="page-link" to="#">Next</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
               </div>
            </div>
      </div>
    </>
  );
}

export default Stats;

