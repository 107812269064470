import React, { useState } from 'react'
import _fetch from '../config/api';
import { api_url } from '../config/config';

const Profile = () => {

const [name,setname] = useState();
const [email,setemail] = useState();
const [disc,setdisc] = useState();


  const updateProfile = async () => {
    // setbtnDisable(true);
    let data = {
      "name": name,
        "email" : email,
      
      "bio": disc,

    }

    let d = await _fetch(`${api_url}auth/profileUpdate`, "POST", data, {})
    if (d?.status === "success") {
    //   toasted.success(d?.message);
      getUserInfo();
    //   setbtnDisable(false);

    }
    else {
    //   setbtnDisable(false);
    //   toasted.error(d?.message);
    }

  }


  const getUserInfo = async () => {
    // setLoading(true);
    let res = await _fetch(`${api_url}user/userInfo/`, "GET", {});

    if (res?.status === "success") {
    //   setLoading(false);
      setname(res?.data?.data?.name);
      setemail(res?.data?.data?.email);
      setdisc(res?.data?.data?.bio);
    }
  }
    return (
        <>
            <div className='fxied-width-container'>
                <div className='dashboard-data-container'>
                    <div className='partner-page profile_page'>
                        <div className='row m-0 '>
                            <div className='col-12 '>
                                <div className='partner-page-header'>
                                    <p> <span> Dashboard /</span> Profile </p>
                                    <h1 className='mb-0'>Profile </h1>
                                </div>
                            </div>

                            <div className='col-12'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <form>
                                            <div className='profile_card'>
                                                <div className='profile_add-pic'>
                                                    <div className=''>

                                                        <label htmlFor='upload__pic'>
                                                            <div className='upload__img'>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" id="gallery"><g fill="none" fill-rule="evenodd" stroke="#ddd"><rect width="19" height="19" x="2.5" y="2.5" rx="2"></rect><path stroke-linejoin="round" d="m4.868 19 3.766-9.048 4.23 7.436 2.404-4.007L19.062 19z"></path><circle cx="16" cy="7.133" r="2.133"></circle></g></svg>
                                                            </div>
                                                            <span> choose your photo</span>
                                                            <input type='file' id='upload__pic' style={{ display: 'none' }} />
                                                        </label>
                                                    </div>

                                                </div>

                                                <div className='form-group'>
                                                    <label htmlFor='nickName'> Nickname</label>
                                                    <input type='text' placeholder='nickname' id='nickName' className='form-control' onChange={(e) => {setname(e.target.value)}} value={name}/>
                                                </div>

                                                <div className='form-group'>
                                                    <label htmlFor='email__edit'> Email</label>
                                                    <input type='text' placeholder='Email' id='email__edit' className='form-control' value={email} onChange={(e) => {setemail(e.target.value)}} />
                                                </div>

                                                <div className='form-group'>
                                                    <label htmlFor='description'> description</label>
                                                    <textarea placeholder='description' id='description' className='form-control' onChange={(e) => {setdisc(e.target.value)}} rows="6">{disc}</textarea>
                                                </div>

                                                <button type='button' onClick={updateProfile} className='btn profile_button'> Save Changes</button>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile