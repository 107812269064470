import React, { useEffect, useState } from 'react'
import { CurrencySec, api_url, blockChain_url, calcuTime, title } from '../config/config';
// import { CopyAll, Launch } from '@mui/icons-material';
import Fade from 'react-reveal/Fade';
import _fetch from '../config/api';
import dateFormat from 'dateformat';
import Loader from '../config/Loader';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { CopyAll, PersonAddAltRounded, SettingsSharp } from '@mui/icons-material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Link } from 'react-router-dom';





const SecSec = () => {
    const [getTransactions, setgetTransactions] = useState();
    const [getTransactoin, setgetTransactoin] = useState();

    const getTransaction = async () => {
        const formData = {
        }
        const data = await _fetch(`${api_url}activation/recentActivity?page=1`, 'get', formData, {});
        // if (data.status === 'success') {
        setgetTransactoin(data?.data);
        // } else {
        //     // setregisterStatus(false);
        // }
    }
    useEffect(() => {
        getTransaction()
    }, [])
    const copyToClipboard = (address) => {
        var textField = document.createElement('textarea');
        textField.innerText = address;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();
        // toasted.success(name + 'Address copied successfull!');
    }

    return (
        <>

            <div className='New___ek__container'>
                <div className="hero-bg-gradient1"></div>
                <div className="hero-bg-gradient2"></div>
                <div className="hero-gradient-ball alltuchtopdown"></div>
                <div className='market__activity'>
                    <div className='coustom_container'>
                        <Fade bottom>

                            <div className='top-activity'>
                                <h3 className='heading__card_black'> Platform recent activity</h3>
                                <p>Real-time global events of the {title} Platform</p>
                            </div>
                        </Fade>

                        <div className='laptop__bg__new_contianer'>
                            <div className='laptop__bg__new'>

                                <div className='table_card__main'>
                                    <div className='card border-0 table-card'>
                                        <div class="table-responsive-xl">
                                            <Fade bottom>
                                                <div className=''>

                                                    <table class="table">
                                                        {/* <thead>
                                                            <tr>
                                                                <th className='text-start'>Date</th>
                                                                <th>Address</th>
                                                                <th>Amount</th>
                                                            </tr>
                                                        </thead> */}
                                                        <tbody>
                                                            {getTransactoin ? '' : <Loader LoaderTxt="Please wait..." />}

                                                            {getTransactoin?.map((val, key) => {
                                                                return (
                                                                    <tr key={key}>
                                                                        <td>
                                                                            <div className='id_profile'>
                                                                                {val?.description !== 'new user joined' && val?.description !== 'new upgrade user' ?

                                                                                    <div className='id_icon id_wallet'>
                                                                                        <AccountBalanceWalletIcon />
                                                                                    </div>

                                                                                    :
                                                                                    <div className='id_icon addUser'>
                                                                                        <PersonAddAltRounded />
                                                                                    </div>
                                                                                }

                                                                                <div className='reffral_txt'>
                                                                                    <span className='__txt'>{val?.description == 'new user joined' || val?.description == 'new upgrade user' ? <span className='description_tt'> {val?.description} </span> : ''}</span>
                                                                                    <Link className='btn'> {val?.username == '0x0000000000000000000000000000000000000000' ? 'Burning' : 'ID ' + val?.username}</Link>
                                                                                </div>

                                                                                {val?.description !== 'new user joined' && val?.description !== 'new upgrade user' && <p className='blanace-profit' > +{val?.amount?.toFixed(2)} {CurrencySec}  {val?.description !== 'new user joined' ? val?.type !== 'auto_pool_energy' ? <p className='__xvalue'> in <span>x2</span> </p> : <p className='__xvalue'> <span>x3</span> </p> : ''}</p>}
                                                                            </div>
                                                                        </td>
                                                                        {/* <td>{val?.type?.replace('_', ' ')?.toUpperCase()}</td> */}
                                                                        {/* <td >
                                                                    <p className='New__overflow__id'><span>{val?.description}</span></p>
                                                                </td> */}

                                                                        {/* <td> {val?.description?.substring(0, 30) + '....' + val?.description?.substring(val?.description?.length - 0, val?.description?.length)}</td> */}
                                                                        <td className='time text-center'>
                                                                            <div className='time-a'>
                                                                                <a href={blockChain_url + val?.hash} target='_blank'>    <OpenInNewIcon /> </a>
                                                                                <p>{calcuTime(val.created_at)}</p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Fade>
                                        </div>
                                        {/* <div className='pagination-area'>
                                            <Fade bottom>
                                                <div className=''>

                                                    <ul class="pagination justify-content-center">
                                                        <li class="page-item"><a class="page-link" href="#">Previous</a></li>
                                                        <li class="page-item active"><a class="page-link" href="#">1</a></li>
                                                        <li class="page-item "><a class="page-link" href="#">2</a></li>
                                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                        <li class="page-item"><a class="page-link" href="#">Next</a></li>
                                                    </ul>
                                                </div>
                                            </Fade>
                                        </div> */}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                {/* <div className='text-center platform_sec_home'>
                    <Fade top>
                        <h2 className='fw-bold heading__card_black'>Need help on how to use <br /> the platform?</h2>
                        <p>Get qualified support from  {title?.toLocaleLowerCase()} experts via online chat</p>
                        <div className='platform-area'>
                            <Link to="/" className='btn'> Contact support </Link>
                        </div>
                    </Fade>

                </div> */}
            </div>

        </>
    )
}

export default SecSec
