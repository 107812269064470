import React from 'react';

const DashboardFooter = () => {
  return (
    <>
        <div className='fxied-width-container'>
            <div className='Footer dashboard-data-container d-flex justify-content-center align-items-center'>
                <p className='mb-0'>Copyright &copy; {(new Date().getFullYear())} All rights reserved </p>
                {/* <p className='mb-0'> Documents</p> */}
            </div>
        </div>
    </>
  );
}

export default DashboardFooter;
